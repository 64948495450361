<script setup>
import { ref, onMounted, onBeforeUnmount, onBeforeMount, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import axios from "axios";
import logo from "@/assets/logo.png";
import bgImg from "@/assets/img/bg-image.png";
import Navbar from "@/components/PageLayout/Navbar.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];
const backgroundStyle = computed(() => {
  return {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    alignItems: "center",
  };
});

const route = useRoute();
const router = useRouter();
const store = useStore();
const toast = useToast();
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const verificationStatus = ref(null); // Holds the status of the token verification
const account_id = computed(() => store.getters["user/accountId"]?.accountId);

let email = "";
let password = "";
let confirm_password = "";
let accountId = "";

onBeforeMount(() => {
  store.state.app.hideConfigButton = true;
  store.state.app.showNavbar = false;
  store.state.app.showSidenav = false;
  store.state.app.showFooter = false;
  body.classList.remove("bg-gray-100");
});

onBeforeUnmount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

const verifyToken = async (token) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_URL}/users/verification-email`,
      { token }
    );

    if (response.data.status) {
      verificationStatus.value = true;
      email = response.data.data.email;
    } else {
      verificationStatus.value = false;
    }
  } catch (error) {
    verificationStatus.value = "error";
    console.error("Token verification failed:", error);
  }
};

// Automatically call `verifyToken` when the page loads
onMounted(() => {
  const token = route.query.token;

  if (token) {
    verifyToken(token);
  } else {
    verificationStatus.value = "missing";
  }
});

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const toggleConfirmPassword = () => {
  showConfirmPassword.value = !showConfirmPassword.value;
};

const changePassword = async () => {
  // Check if fields are empty
  if (!password || !confirm_password) {
    return toast.warning("Please fill in the fields!", {
      position: "top-right",
    });
  }

  // Check password length
  if (password.length < 6 || confirm_password.length < 6) {
    return toast.warning("Password must be at least 6 characters long", {
      position: "top-right",
    });
  }

  // Check if passwords match
  if (password !== confirm_password) {
    return toast.warning("Passwords do not match", {
      position: "top-right",
    });
  }

  try {
    accountId = account_id.value;
    const result = await store.dispatch("user/resetPassword", {
      accountId,
      email,
      password,
    });

    toast.success(result.message || "Password changed successfully", {
      position: "top-right",
    });

    router.push("/");
  } catch (error) {
    // Show error message if the API call failed
    toast.error(error.message || "Failed to change password", {
      position: "top-right",
    });
  }
};

const clickStartOVer = () => {
  router.push("/forgot-password");
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>

  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4
                    v-if="verificationStatus === false"
                    class="font-weight-bolder"
                  >
                    Password recovery error
                  </h4>
                  <h4
                    v-if="verificationStatus === true"
                    class="font-weight-bolder"
                  >
                    New Password
                  </h4>
                  <p
                    v-if="verificationStatus === false"
                    class="mb-0 password-recovery-content"
                  >
                    SoapBox could not locate the information needed to recover
                    your password. Please try again.
                  </p>
                </div>
                <div class="card-body">
                  <form
                    v-if="verificationStatus === true"
                    role="form"
                    @submit.prevent="changePassword"
                  >
                    <div class="mb-3 position-relative">
                      <argon-input
                        id="password"
                        :type="showPassword ? 'text' : 'password'"
                        placeholder="Enter Password"
                        name="password"
                        size="lg"
                        v-model="password"
                      />
                      <button
                        type="button"
                        class="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 pe-3"
                        @click="togglePassword"
                        :aria-label="
                          showPassword ? 'Hide password' : 'Show password'
                        "
                        style="z-index: 2"
                      >
                        <i
                          class="fa"
                          :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                          style="color: #8392ab"
                        ></i>
                      </button>
                    </div>
                    <div class="mb-3 position-relative">
                      <argon-input
                        id="confirm_password"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        size="lg"
                        v-model="confirm_password"
                      />
                      <button
                        type="button"
                        class="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 pe-3"
                        @click="toggleConfirmPassword"
                        :aria-label="
                          showConfirmPassword
                            ? 'Hide password'
                            : 'Show password'
                        "
                        style="z-index: 2"
                      >
                        <i
                          class="fa"
                          :class="
                            showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                          "
                          style="color: #8392ab"
                        ></i>
                      </button>
                    </div>
                    <div class="text-center">
                      <argon-button
                        class="mt-2"
                        variant="gradient"
                        color="dark"
                        fullWidth
                        size="lg"
                        >Change Password
                      </argon-button>
                    </div>
                  </form>
                  <div v-if="verificationStatus === false" class="text-center">
                    <argon-button
                      variant="gradient"
                      color="dark"
                      fullWidth
                      size="lg"
                      @click="clickStartOVer"
                      >Start Over
                    </argon-button>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden gap-3"
                :style="backgroundStyle"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <img
                  :src="logo"
                  class="w-50 position-relative"
                  alt="main_logo"
                />
                <p class="text-white position-relative">
                  Streamlined messaging platform for effortless, meaningful
                  connections. Simplify your communication and amplify your
                  connections with our intuitive interface and powerful
                  features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style>
/* Add your styling here */
.password-recovery-content {
  margin-top: 4px;
}
</style>
