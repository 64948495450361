<script setup>
defineProps({
  color: {
    type: String,
    default: "success",
  },
  size: {
    type: String,
    default: "md",
  },
  variant: {
    type: String,
    default: "fill",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const getClasses = (variant, color, size, fullWidth, active) => {
  let colorValue, sizeValue, fullWidthValue, activeValue;

  // Setting the button variant and color
  if (variant === "gradient") {
    colorValue = `bg-gradient-${color}`;
  } else if (variant === "outline") {
    colorValue = `btn-outline-${color}`;
  } else {
    colorValue = `btn-${color}`;
  }

  sizeValue = size ? `btn-${size}` : null;

  fullWidthValue = fullWidth ? `w-100` : null;

  activeValue = active ? `active` : null;

  return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue} btn-click-effect`;
};
</script>

<template>
  <button
    class="btn mb-0"
    :disabled="disabled"
    :class="getClasses(variant, color, size, fullWidth, active)"
  >
    <slot />
  </button>
</template>

<style scoped>
.btn-click-effect {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.15s ease-in-out;
}

.btn-click-effect:active:not(:disabled) {
  transform: scale(0.95);
}

.btn-click-effect::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.btn-click-effect:active:not(:disabled)::after {
  animation: ripple 0.4s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  20% {
    transform: scale(25, 25);
    opacity: 0.3;
  }
  100% {
    transform: scale(40, 40);
    opacity: 0;
  }
}

/* For outline variant, adjust ripple color */
.btn-outline-* .btn-click-effect::after {
  background: rgba(0, 0, 0, 0.2);
}
</style>