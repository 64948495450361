<script setup>
import { computed, ref, watch, onMounted } from "vue";
import { getStates } from "@/utils";
import { useStore } from "vuex";
import { useToast } from 'vue-toast-notification';
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import { SegmentedMessage } from 'sms-segments-calculator';

const store = useStore();
const $toast = useToast();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const associatedSipPeers = computed(() => store.getters["jobData/getAssociatedSipPeers"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const agent = computed(() => store.getters["agentData/getSelectedAgent"]);
const editAgentInfo = computed(() => store.getters["agentData/getEditAgentInfo"]);
const editAgentId = computed(() => store.getters["agentData/getEditAgentId"]);
const error = computed(() => store.getters["agentData/getError"]);
const isRTL = computed(() => store.state.isRTL);
const assignedMedia = computed(() => store.getters["mediaData/getAssignedMedia"]);
const siteInserviceNumbers = computed(() => store.getters["jobData/getSiteInserviceNumbers"]);  
const jobAreaStates = ref([]);
const isBitlyUrl = ref(false);
const editorContent = ref('');
const timeSelection = ref('schedule');
const localDateTime = ref(null);
const phoneNumberOptions = ref([
  { id: 1, name: "Existing Number" },
  { id: 2, name: "New Number" },
]);
const isSelectedMedia = ref(false);

const formData = ref({});
const selectedPhoneNumberOption = ref(1);
const bitlyUrl = ref('');
const isEditMode = ref(false);
const isLoading = ref(false);
const agentData = ref({
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  role: 'AGENT-ADMIN' // Default role
});

watch(
  () => formData.value.initialTemplateMessage,
  async (newValue) => {
    if (newValue) {
      try {
        const segmentedMessage = new SegmentedMessage(newValue, "auto", true);
        let processedContent = segmentedMessage.graphemes.join("");
        editorContent.value = processedContent; // Update Quill editor content
        console.log("Processed content:", processedContent);
      } catch (error) {
        console.error("Error processing message:", error);
      }
    }
  },
  { deep: true, immediate: true }
);

watch(
  () => timeSelection.value,
  async (newValue) => {
    if (newValue === 'now') {
      localDateTime.value = new Date();
      await convertToUTC();
    }
  }
);


const urlConverter = async () => {
  if (bitlyUrl.value) {
    isBitlyUrl.value = true
    const url = await shortenUrl(bitlyUrl.value)
    isBitlyUrl.value = false

    bitlyUrl.value = url
  }
}

async function shortenUrl(longUrl) {
  // Add https:// to the URL if it doesn't have a protocol
  try{
    const fullUrl = longUrl.startsWith('http') ? longUrl : `https://${longUrl}`;

    const response = await fetch('https://api-ssl.bitly.com/v4/bitlinks', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${process.env.VUE_APP_BITLY_TOKEN}`, // Replace with your actual Bitly access token
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "long_url": fullUrl,
        "domain": "20win24.com" // You can change this if you have a custom domain
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    
    // Remove the protocol from the shortened URL to match the original format
    return data.link;
  } catch(err){
    console.log(err);
    
  }
}

const validateForm = () => {
  const errors = [];

  if (!agentData.value.email) {
    errors.push('Email is required.');
  } else if (!/^\S+@\S+\.\S+$/.test(agentData.value.email)) {
    errors.push('Invalid email format.');
  }

  if (!agentData.value.firstName) {
    errors.push('First name is required.');
  }

  if (!agentData.value.lastName) {
    errors.push('Last name is required.');
  }

  if (!isEditMode.value) {
    if (!agentData.value.password ) {
      errors.push('Password is required.');
    } else if (agentData.value.password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
  }

  if (!agentData.value.role) {
    errors.push('Role is required.');
  }

  return errors;
};

onMounted(() => {
  jobAreaStates.value = [
    {
      name: "US National (USA)",
      code: "USA",
    },
    {
      name: "Canada National (CAN)",
      code: "CAN",
    },
    ...getStates(),
  ];
  store.dispatch("jobData/fetchAssociatedSipPeers");
  store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  formData.value.existingNumbers = formData.value.existingNumbers || [];
  localDateTime.value = formData.value.startDate || null
});

watch(assignedMedia, (newAssignedMedia) => {
  if (newAssignedMedia && formData.value.identity && formData.value.isMMSEnabled) {
    if (newAssignedMedia.thumbnailUrl) formData.value.initialTemplateMedia = newAssignedMedia.thumbnailUrl;
  } else {
    formData.value.initialTemplateMedia = null;
  }
}, { immediate: true });

watch(editAgentInfo, (newValue) => {
  if (newValue && editAgentId.value !== "") {
    console.log(editAgentId.value, 11);
    
    isEditMode.value = true;
    // Populate the form with the edit agent info
    agentData.value = {
      email: newValue.displayEmail || '',
      firstName: newValue.firstName || '',
      lastName: newValue.lastName || '',
      role: newValue.role || 'AGENT-READER'
    };
  }
}, { immediate: true });

const toggleEditJobPanel = () => {
  store.commit("jobData/toggleEditJobPanel")
  agentData.value = {
      email: '',
      password: '', // Clear password for security reasons
      firstName: '',
      lastName: '',
      role: 'AGENT-READER'
    };
  isEditMode.value = false;
};

const saveJob = async () => {
  const errors = validateForm();
  if (errors.length > 0) {
    $toast.warning(errors[0], { position: "top-right" });

    return;
  }
  isLoading.value = true;
  try {
    if (!isEditMode.value) {
      const payload = {
        accountId: userId.value,
        email: agentData.value.email,
        firstName: agentData.value.firstName,
        lastName: agentData.value.lastName,
        password: agentData.value.password,
        role: agentData.value.role,
        domain: window.location.hostname,
      };
      await store.dispatch("agentData/addAgent", payload);
      $toast.success('Agent created successfully!', { position: "top-right" });
    } else {
      const payload = {
        agentId: editAgentInfo.value.id,
        email: agentData.value.email,
        firstName: agentData.value.firstName,
        lastName: agentData.value.lastName,
        role: agentData.value.role,
        domain: window.location.hostname,
      };
      await store.dispatch("agentData/updateAgent", payload);
      $toast.success('Agent updated successfully!', { position: "top-right" });
    }
    toggleEditJobPanel();
    agentData.value = {};
    if (!error.value) {
      await store.dispatch("agentData/fetchAgents", { userId: userId.value });
      store.commit("jobData/addAgentStepData", agent.value);
    }
  }  catch (err) {
    error.value = err;
    $toast.error(`Failed to ${isEditMode.value ? 'update' : 'create'} agent. Please try again.`, { position: "top-right" });
    console.error(`Error ${isEditMode.value ? 'updating' : 'creating'} agent:`, err);
  } finally {
    isLoading.value = false;
  }
  
};

const onIdentitySelect = (identity) => {
  formData.value.identity = identity;
  const site = associatedSipPeers.value?.find(item => item.SiteName == identity.identityName);

  if (site) {
    store.dispatch("jobData/fetchSiteInserviceNumbers", { siteId: site.SiteId, sippeerId: site.PeerId });
  }
};

const selectPhoneNumberOption = (option) => {
  selectedPhoneNumberOption.value = option.id;

  if (option.id === 1) {
    store.dispatch("jobData/fetchPurchasedPhoneNumbers");
  }
};

const onExistingNumberSelect = (existingNumber) => {
  formData.value.existingNumber = existingNumber;
  formData.value.adminPhone = existingNumber;
};

const onJobAreaStateSelect = (jobArea) => {
  formData.value.jobArea = jobArea;
  formData.value.allAreaCodes = [];
};

const onAreaCodeSelect = (areaCode) => {
  const index = formData.value.allAreaCodes?.indexOf(areaCode);
  if (index > -1) {
    formData.value.allAreaCodes?.splice(index, 1);
  } else {
    formData.value.allAreaCodes?.push(areaCode);
  }
};

const onMMSEnabledClick = () => {
  formData.value.isMMSEnabled = !formData.value.isMMSEnabled;
  store.commit("mediaData/setAssignedMedia", null);
    // formData.value.initialTemplateMedia = "";
};

const onBrowse = async () => {
  await store.commit("mediaData/setSelectedIdentity", formData.value.identity);
  await store.commit("mediaData/toggleAssignMediaPanel");
  isSelectedMedia.value=true
};

const updateContent = (content) => {
  formData.value.initialTemplateMessage = content;
};

const convertToUTC = (localDateString) => {
  if (localDateString) {
    // Parse the local date string
    const localDate = new Date(localDateString);
    
    // Get the timezone offset in minutes
    
    // Convert local time to UTC by adding the timezone offset
    const utcDate = new Date(localDate.getTime());
    
    // Format the UTC date as an ISO string
    formData.value.startDate = utcDate.toISOString();
    
    console.log('Selected local time:', localDate.toLocaleString());
    console.log('Converted UTC time:', formData.value.startDate);
  } else {
    const localDate = new Date();
    
    // Get the timezone offset in minutes
    
    // Convert local time to UTC by adding the timezone offset
    const utcDate = new Date(localDate.getTime());
    
    // Format the UTC date as an ISO string
    formData.value.startDate = utcDate.toISOString();
    
    console.log('Selected local time:', localDate.toLocaleString());
    console.log('Converted UTC time:', formData.value.startDate);
  }
};

</script>
<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card p-4 overflow-auto">
        <div class="pb-0">
            <div class="" :class="isRTL ? 'float-end' : 'float-start'">
            <h5 class="mt-3">Edit Job</h5>
            </div>
            <div
            class="mt-4"
            @click="toggleEditJobPanel"
            :class="isRTL ? 'float-start' : 'float-end'"
            >
            <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
                <i class="fa fa-close"></i>
            </button>
            </div>
        </div>
        <div class="mb-3">
            <label for="job-name-input" class="form-control-label">Job Name:</label>
            <argon-input type="text" v-model="formData.jobName" />
        </div>
        <div v-if="!isEditMode" class="mb-3">
            <label>Identity:</label>
            <argon-dropdown class="">
                <template v-slot:title>
                <argon-button color="light" class="dropdown-toggle">
                    {{ formData.identity?.identityName || "Identity" }}
                </argon-button>
                </template>
                <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                >
                    <label class="mb-0">{{ identity.identityName }}</label>
                </a>
            </argon-dropdown>
        </div>
        <div class="mb-3">
            <div v-for="option in phoneNumberOptions" :key="option.id">
            <label>
                <input 
                    type="radio" 
                    :value="option.id" 
                    :checked="selectedPhoneNumberOption === option.id"
                    @change="selectPhoneNumberOption(option)"
                />
                {{ option.name }}
            </label>
            </div>
        </div>
        <div v-if="selectedPhoneNumberOption === 1" class="mb-3">
            <argon-dropdown class="">
            <template v-slot:title>
                <argon-button color="light" class="dropdown-toggle">
                {{ formData.existingNumber || "Select Phone Number" }}
                </argon-button>
            </template>
            <a
                v-for="(phoneNumber, index) in siteInserviceNumbers"
                :key="index"
                class="dropdown-item d-flex align-items-center"
                @click="onExistingNumberSelect(phoneNumber)"
            >
                <label class="mb-0">{{ phoneNumber }}</label>
            </a>
            </argon-dropdown>
        </div>
        <div v-if="selectedPhoneNumberOption === 2" class="mb-3">
            <label for="job-area-input" class="form-control-label">Job Area:</label>
            <p>
            Your numbers (DIDs) for your agents will be automatically leased to you based
            on the state (and area codes) you select. If there are no available numbers
            where you have selected, the next best available number from the state, then
            country will be chosen. If US or Canada National is selected, the numbers will
            be leased from random area codes in that country.
            </p>
            <!-- <argon-input type="text" v-model="formData.jobArea" /> -->
            <argon-dropdown class="">
            <template v-slot:title>
                <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData?.jobArea?.name || "US National (USA)" }}
                </argon-button>
            </template>
            <a
                v-for="(jobArea, index) in jobAreaStates"
                :key="index"
                class="dropdown-item d-flex align-items-center"
                @click="onJobAreaStateSelect(jobArea)"
            >
                <label class="mb-0">{{ jobArea?.name }}</label>
            </a>
            </argon-dropdown>
        </div>
        <div v-if="selectedPhoneNumberOption === 2 && formData.jobArea && !['USA', 'CAN'].includes(formData.jobArea?.code)" class="mb-3">
            <label for="all-area-codes-input" class="form-control-label">
            All Area Codes:
            </label>
            <!-- <argon-input type="text" v-model="allAreaCodes" /> -->
            <argon-dropdown class="">
            <template v-slot:title>
                <argon-button color="light" class="w-100 dropdown-toggle">
                {{ formData.allAreaCodes?.join(", ") }}
                </argon-button>
            </template>
            <a
                v-for="(areaCode, index) in formData.jobArea?.areaCodes"
                :key="index"
                class="dropdown-item d-flex align-items-center"
            >
                <input
                type="checkbox"
                :id="'areaCode-' + index"
                value="formData?.allAreaCodes.includes(areaCode)"
                @change="onAreaCodeSelect(areaCode)"
                />
                <label class="mb-0">{{ areaCode }}</label>
            </a>
            </argon-dropdown>
        </div>
        <div class="mb-3">
            <label for="lastname-input" class="form-control-label">MMS Enabled:</label>
            <div class="form-check form-switch my-auto">
            <input
            class="mt-1 form-check-input"
            type="checkbox"
            id="mmsEnabled"
            :checked="formData.isMMSEnabled"
            @click="onMMSEnabledClick"
            />
        </div>
        </div>
        <div class="mb-3">
            <label class="form-control-label">Initial Template Media:</label>
            <div class="d-flex">
            <div class="flex-grow-1" style="display: none;">
                <argon-input
                    readonly
                    v-model="formData.initialTemplateMedia"
                    class="mb-0"
                    />
                </div>
                <div>
                    <argon-button
                    class="mx-2 px-2"
                    :disabled="!formData.identity"
                    @click.prevent="onBrowse()"
                    >
                    Browse
                    </argon-button>
                </div>
                <div v-if="formData.initialTemplateMedia">
                    <div v-if="assignedMedia.mediaType === 'IMAGE'" class="media-container">
                    <img :src="formData.initialTemplateMedia" alt="Image" class="media">
                    </div>
                    <div v-else-if="assignedMedia.mediaType === 'VIDEO'" class="media-container">
                    <video controls class="media">
                        <source :src="formData.initialTemplateMedia" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    </div>
                </div>
            </div>
            <div v-if="!formData.identity" class="text-red">You must select an identity to assign a media</div>
        </div>
        <div class="mb-3">
            <label class="form-control-label">Initial Template Message:</label>
            <QuillEditor
                ref="quillEditor"
                theme="snow"
                id="initialTemplateMessageEditor"
                :content="editorContent"
                @update:content="updateContent"
                content-type="html"
            />
        </div>
        <div class="mb-3">
            <label for="job-name-input" class="form-control-label">Short Link Generator:</label>
            <argon-input type="text" v-model="bitlyUrl"/>
            <argon-button
                color="success"
                size="sm"
                :disabled="isBitlyUrl"
                @click="urlConverter"
            >
                Shorten URL
            </argon-button>
        </div>
        <div class="mb-3">
            <label class="form-control-label">Start Time:</label>
            <VueDatePicker 
                v-model="localDateTime" 
                format="yyyy-MM-dd HH:mm:ss"
                @update:modelValue="convertToUTC"
                time-picker-inline
            ></VueDatePicker>
        </div>
      <div class="text-left">
          <argon-button
            class="border-radius-2xl"
            variant="gradient"
            color="dark"
            :disabled="isLoading"
            size="md"
            @click="saveJob"
          >
            {{ isLoading ? "Saving..." : "Save" }}
          </argon-button>
        </div>
    </div>
  </div>
</template>
