<script setup>
import { onBeforeMount, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { toE164 } from "@/utils";
import { useToast } from 'vue-toast-notification';
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ContentForm from "./ContentForm.vue";
import ReplyTemplatesForm from "./ReplyTemplatesForm.vue";
import AgentsForm from "./AgentsForm.vue";
// import DeliverySchedule from "./DeliverySchedule.vue";
import PhoneList from "./PhoneList.vue";
// import SuppressionList from "./SuppressionList.vue";

const body = document.getElementsByTagName("body")[0];
const $toast = useToast();
const store = useStore();
const router = useRouter();

const userId = computed(() => store.getters["user/currentUser"].id);
const jobType = computed(() => store.getters["jobData/getJobType"]);
const editContent = computed(() => store.getters["jobData/getEditContent"]);
const status = computed(() => store.getters["jobData/getStatus"]);
const agents = computed(() => store.getters["agentData/getAgents"]);
const phoneLists = computed(() => store.getters["phoneListData/getPhoneLists"]);
const suppressionLists = computed(
  () => store.getters["phoneListData/getSuppressionLists"]
);

const steps = ref([
  { title: "Content" },
  { title: "Reply Templates" },
  { title: "Agents" },
  { title: "Phone List" },
  { title: "Suppression List" },
]);
const currentStep = ref(0);
const isValid = ref(false);
const editMode = ref(false);
const contentStepData = ref({});
const replyTemplatesStepData = ref({});
const agentsStepData = ref({});
// const deliveryScheduleStepData = ref({});
const phoneListStepData = ref({});
const suppressionListStepData = ref({});

watch(store.state, () => {
  loadData();
});

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
  if (router.currentRoute.value.href.includes("/job/edit")){
    editMode.value=true
    contentStepData.value=editContent.value
    console.log(editContent.value);
    store.commit("jobData/setAssignedPhoneListStepData", editContent.value.phoneListAssignments) 
  }
  else {
    editMode.value=false
    store.commit("jobData/initJob");
  }
});

onMounted(async () => {
  
  store.state.app.isAbsolute = true;
  await store.dispatch("identityData/fetchIdentities", { userId: userId.value });
  await store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });
  await store.dispatch("phoneListData/fetchSuppressionLists", { userId: userId.value });
  await store.dispatch("user/fetchUsers")

  agentsStepData.value = {
    available: [...agents.value],
    assigned: editMode.value ? editContent.value.agents : [],
  };
  store.commit("jobData/updateAgentsStepData", agentsStepData.value);
  
  phoneListStepData.value = {
    available: [...phoneLists.value],
    assigned: editMode.value ? editContent.value.phoneListAssignments: [],
  };
  if (editMode.value) {
    store.commit("jobData/setJobType", editContent.value.jobType)
  }
  store.commit("jobData/updatePhoneListStepData", phoneListStepData.value);
  suppressionListStepData.value = {
    available: [...suppressionLists.value],
    assigned: editMode.value ? editContent.value.suppressionListAssignments : [],
  };
  store.commit("jobData/updateSuppressionListStepData", suppressionListStepData.value);
});

const loadData = () => {
  // contentStepData.value = store.state.jobData.content;
  replyTemplatesStepData.value = store.state.jobData.replyTemplates;
  agentsStepData.value = store.state.jobData.agents;
  // deliveryScheduleStepData.value = store.state.jobData.deliverySchedule;
  phoneListStepData.value = store.state.jobData.phoneLists;
  suppressionListStepData.value = store.state.jobData.suppressionLists;
  isValid.value = store.state.jobData.isValid;
};

const updateValidState = (isValidState) => {
  isValid.value = isValidState;
  store.commit("jobData/updateValid", isValidState);
};

const updateContentStepData = (stepData) => {
  contentStepData.value = stepData;
};

const updateReplyTemplatesStepData = (stepData) => {
  replyTemplatesStepData.value = stepData;
};

const onAddTemplate = (templateData) => {
  replyTemplatesStepData.value = {
    ...replyTemplatesStepData.value,
    templates: [...replyTemplatesStepData.value.templates, templateData],
  };
  store.commit("jobData/updateReplyTemplatesStepData", replyTemplatesStepData.value);
};

const onUpdateTemplate = (templateData) => {
  replyTemplatesStepData.value.templates[templateData.id] = {
    title: templateData.title,
    text: templateData.text,
    media: templateData.media,
  };
  store.commit("jobData/updateReplyTemplatesStepData", replyTemplatesStepData.value);
};

const onRemoveTemplate = (templateData) => {
  const templateIndex = replyTemplatesStepData.value.templates.findIndex(item => item.id === templateData.id);
  replyTemplatesStepData.value = {
    ...replyTemplatesStepData.value,
    templates: replyTemplatesStepData.value.templates.splice(templateIndex, 1),
  };
};

const onAddAgent = () => {
  store.commit("jobData/toggleAddAgentPanel");
};

const onAddPhoneList = () => {
  store.commit("jobData/toggleAddPhoneListPanel");
};

const onBackClick = () => {
  if (currentStep.value > 0) {
    if (jobType.value !== 'P2P' && currentStep.value === 3) {
      currentStep.value -= 2;
    } else {
      currentStep.value--;
    }
  }
};

const onContinueClick = (index) => {
 
  if (!isValid.value) {
    $toast.warning('Please fill in the fields!', { position: "top-right" });
    return;
  }
  if (currentStep.value < steps.value.length - 1) {
    if (currentStep.value === 0) {
      store.commit("jobData/updateContentStepData", contentStepData.value);
    }
    if (currentStep.value === 1) {
      store.commit("jobData/updateReplyTemplatesStepData", replyTemplatesStepData.value);
    }
    if (currentStep.value === 2) {
      store.commit("jobData/updateAgentsStepData", agentsStepData.value);
    }
    if (currentStep.value === 3) {
      store.commit("jobData/updatePhoneListStepData", phoneListStepData.value);
    }
    if (currentStep.value === 4) {
      store.commit(
        "jobData/updateSuppressionListStepData",
        suppressionListStepData.value
      );
    }

    if (index < 0) {
      if (jobType.value !== 'P2P' && currentStep.value === 1) {
        currentStep.value += 2;
      } else {
        currentStep.value++;
      }
    } else {
      currentStep.value = index;
    }
  }
};

// const saveSchedule = async () => {
//   const {
//     scheduleName = "Custom",
//     scheduleTimezone,
//     sunStart,
//     sunEnd,
//     monStart,
//     monEnd,
//     tueStart,
//     tueEnd,
//     wedStart,
//     wedEnd,
//     thuStart,
//     thuEnd,
//     friStart,
//     friEnd,
//     satStart,
//     satEnd,
//   } = deliveryScheduleStepData.value;
//   const payload = {
//     accountId: userId.value,
//     friEnd,
//     friStart,
//     isGlobal: 0,
//     monEnd,
//     monStart,
//     satEnd,
//     satStart,
//     scheduleName,
//     scheduleTimezone,
//     sunEnd,
//     sunStart,
//     thuEnd,
//     thuStart,
//     tueEnd,
//     tueStart,
//     wedEnd,
//     wedStart,
//   };
//   await store.dispatch("jobData/addSchedule", payload);
//   deliveryScheduleStepData.value = {};
// };

const saveJob = async () => {
  const {
    jobName,
    identity,
    isMMSEnabled,
    adminPhone,
    initialTemplateMessage,
    initialTemplateMedia,
    startDate
  } = contentStepData.value;
  const { templates } = replyTemplatesStepData.value;
  const { assigned: agents } = agentsStepData.value;
  // const { scheduleId, startDate, endDate } = deliveryScheduleStepData.value;
  const { assigned: phoneListAssignments } = phoneListStepData.value;
  const { assigned: suppressionListAssignments } = suppressionListStepData.value;
  
  const payload = {
    name: jobName,
    jobType: jobType.value,
    accountId: identity.accountId,
    aiAutoOptOutThreshold: "DISABLED",
    aiEnabled: false,
    canUseMms: isMMSEnabled,
    adminPhone: toE164(adminPhone, 1),
    initialTemplateMessage,
    initialTemplateMedia,
    didNpaSubset: [],
    didState: "USA",
    externalBillingId: null,
    identityId: identity.identityId,
    integrations: [],
    agents,
    agentIds: agents.map((agent) => agent.id),
    phoneListAssignments,
    questions: [],
    startDate,
    status: status.value,
    suppressionListAssignments,
    templateIds: templates.map((template) => template.id),
    trackedLinks: [],
  };
  if(editMode.value) await store.dispatch("jobData/editJob", {jobId: editContent.value.jobId, data: payload});
  else await store.dispatch("jobData/addJob", payload);
};

const onFinishClick = async () => {
  // await saveSchedule();
  await saveJob();
  router.push("/");
};
</script>

<template>
  <div class="container-fluid pb-4">
    <div
      class="page-header min-height-300"
      style="
        background-image: url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80');
        margin-right: -24px;
        margin-left: -34%;
      "
    >
      <!-- <span class="mask bg-gradient-success opacity-6"></span> -->
    </div>
    <div class="card shadow-lg mt-n5">
      <div class="card-body p-3">
        <h2 v-if="!editMode" class="text-white mt-n6 mb-3">New P2P SMS Job</h2>
        <h2 v-if="editMode" class="text-white mt-n6 mb-3">Edit P2P SMS Job</h2>
        <div class="row gx-4">
          <div class="mx-auto mt-3 col-lg-12 col-md-12 my-sm-auto ms-sm-auto me-sm-0">
            <div class="nav-wrapper position-relative end-0">
              <ul
                class="p-1 bg-transparent nav nav-pills nav-fill d-flex flex-column flex-lg-row"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 0 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="true"
                    @click="onContinueClick(0)"
                  >
                    <span class="ms-1">Content</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 1 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                    @click="onContinueClick(1)"
                  >
                    <span class="ms-1">Reply Templates</span>
                  </a>
                </li>
                <li v-if="jobType === 'P2P'" class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 2 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                    @click="onContinueClick(2)"
                  >
                    <span class="ms-1">Agents</span>
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 3 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                    @click="onContinueClick(3)"
                  >
                    <span class="ms-1">Delivery Schedule</span>
                  </a>
                </li> -->
                <li class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 3 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                    @click="onContinueClick(3)"
                  >
                    <span class="ms-1">Phone List</span>
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a
                    class="px-0 py-2 mb-0 nav-link"
                    :class="{ active: currentStep === 4 }"
                    data-bs-toggle="tab"
                    href="javascript:;"
                    role="tab"
                    aria-selected="false"
                    @click="onContinueClick(4)"
                  >
                    <span class="ms-1">Suppression List</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4">
      <div class="d-flex justify-content-end py-3 px-4">
        <argon-button color="light" size="sm" class="ms-auto" @click="onBackClick">
          Back
        </argon-button>
        <argon-button
          v-if="currentStep !== 3"
          color="success"
          size="sm"
          class="ms-2"
          @click="onContinueClick(-1)"
        >
          Continue
        </argon-button>
        <argon-button
          v-if="currentStep === 3"
          color="success"
          size="sm"
          class="ms-2"
          :disabled="!isValid"
          @click="onFinishClick"
        >
          Send
        </argon-button>
      </div>
      <div v-if="currentStep == 0" class="card-body">
        <content-form
          :data="contentStepData"
          :emitContentData="updateContentStepData"
          :emitValidState="updateValidState"
          :editMode="editMode"
        />
      </div>
      <div v-if="currentStep == 1" class="card-body">
        <reply-templates-form
          :data="replyTemplatesStepData"
          :emitReplyTemplatesData="updateReplyTemplatesStepData"
          :emitValidState="updateValidState"
          :addTemplate="onAddTemplate"
          :updateTemplate="onUpdateTemplate"
          :removeTemplate="onRemoveTemplate"
        />
      </div>
      <div v-if="jobType == 'P2P' && currentStep == 2" class="card-body">
        <agents-form
          :data="agentsStepData"
          :emitValidState="updateValidState"
          :addAgent="onAddAgent"
        />
      </div>
      <!-- <div v-if="currentStep == 3" class="card-body">
        <delivery-schedule
          :data="deliveryScheduleStepData"
          :emitDeliveryScheduleData="updateScheduleStepData"
        />
      </div> -->
      <div v-if="currentStep == 3" class="card-body">
        <phone-list
          :sendList="phoneListStepData"
          :suppressionList="suppressionListStepData"
          :emitValidState="updateValidState"
          :addPhoneList="onAddPhoneList"
          :addSuppressionList="onAddSuppressionList"
          :identityId="contentStepData.identity.identityId"
        />
      </div>
      <!-- <div v-if="currentStep == 4" class="card-body">
        <suppression-list
          :data="suppressionListStepData"
          :emitValidState="updateValidState"
          :addSuppressionList="onAddSuppressionList"
          :identityId="contentStepData.identity.identityId"
        />
      </div> -->
    </div>
  </div>
</template>
