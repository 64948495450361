import { createApp } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import ToastPlugin from 'vue-toast-notification';
import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import App from './App.vue';
import store from './store';
import router from './router';
import ArgonDashboard from './argon-dashboard';
import phoneMask from './directives/phone-mask.js';

const appInstance = createApp(App);
appInstance.component('QuillEditor', QuillEditor);
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.directive('phone-mask', phoneMask);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(ToastPlugin);
appInstance.mount('#app');
