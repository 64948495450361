<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonButton from "@/components/Core/ArgonButton.vue";

const store = useStore();
const $toast = useToast();
const userId = computed(() => store.getters["user/currentUser"].id);
const agent = computed(() => store.getters["agentData/getSelectedAgent"]);
const editAgentInfo = computed(
  () => store.getters["agentData/getEditAgentInfo"]
);
const editAgentId = computed(() => store.getters["agentData/getEditAgentId"]);
const error = computed(() => store.getters["agentData/getError"]);
const isRTL = computed(() => store.state.isRTL);
const formTitle = computed(() => {
  return isEditMode.value ? "Edit User" : "Create New User";
});

const isEditMode = ref(false);
const isLoading = ref(false);
const agentData = ref({
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  role: "AGENT-ADMIN", // Default role
});
const data = store.getters["user/getUsers"];
const selectedAgencyId = ref(46);

const validateForm = () => {
  const errors = [];

  if (!agentData.value.email) {
    errors.push("Email is required.");
  } else if (!/^\S+@\S+\.\S+$/.test(agentData.value.email)) {
    errors.push("Invalid email format.");
  }

  if (!agentData.value.firstName) {
    errors.push("First name is required.");
  }

  if (!agentData.value.lastName) {
    errors.push("Last name is required.");
  }

  if (!isEditMode.value) {
    if (!agentData.value.password) {
      errors.push("Password is required.");
    } else if (agentData.value.password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
  }

  if (!agentData.value.role) {
    errors.push("Role is required.");
  }

  return errors;
};

watch(
  editAgentInfo,
  (newValue) => {
    if (newValue && editAgentId.value !== "") {
      console.log(editAgentId.value, 11);

      isEditMode.value = true;
      // Populate the form with the edit agent info
      agentData.value = {
        email: newValue.displayEmail || "",
        firstName: newValue.firstName || "",
        lastName: newValue.lastName || "",
        role: newValue.role || "AGENT-READER",
      };
    }
  },
  { immediate: true }
);

const toggleAddAgentPanel = () => {
  store.commit("jobData/toggleAddAgentPanel");
  agentData.value = {
    email: "",
    password: "", // Clear password for security reasons
    firstName: "",
    lastName: "",
    role: "AGENT-READER",
  };
  isEditMode.value = false;
};

const saveAgent = async () => {
  const errors = validateForm();
  if (errors.length > 0) {
    $toast.warning(errors[0], { position: "top-right" });

    return;
  }
  isLoading.value = true;
  try {
    if (!isEditMode.value) {
      const payload = {
        accountId: selectedAgencyId.value,
        email: agentData.value.email,
        firstName: agentData.value.firstName,
        lastName: agentData.value.lastName,
        password: agentData.value.password,
        role: agentData.value.role,
        domain: window.location.hostname,
      };
      await store.dispatch("agentData/addAgent", payload);
      $toast.success("Agent created successfully!", { position: "top-right" });
    } else {
      const payload = {
        agentId: editAgentInfo.value.id,
        email: agentData.value.email,
        firstName: agentData.value.firstName,
        lastName: agentData.value.lastName,
        role: agentData.value.role,
        domain: window.location.hostname,
      };
      await store.dispatch("agentData/updateAgent", payload);
      $toast.success("Agent updated successfully!", { position: "top-right" });
    }
    toggleAddAgentPanel();
    agentData.value = {};
    if (!error.value) {
      await store.dispatch("agentData/fetchAgents", { userId: userId.value });
      store.commit("jobData/addAgentStepData", agent.value);
    }
  } catch (err) {
    error.value = err;
    $toast.error(
      `Failed to ${isEditMode.value ? "update" : "create"} agent. Please try again.`,
      { position: "top-right" }
    );
    console.error(
      `Error ${isEditMode.value ? "updating" : "creating"} agent:`,
      err
    );
  } finally {
    isLoading.value = false;
  }
};
</script>
<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card p-4">
      <div class="pb-0">
        <div class="" :class="isRTL ? 'float-end' : 'float-start'">
          <h5 class="mt-3">User Manager</h5>
          <p>{{ formTitle }}</p>
        </div>
        <div
          class="mt-4"
          @click="toggleAddAgentPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <form role="form" @submit.prevent="saveAgent">
        <div class="mb-3">
          <label for="email-input" class="form-control-label">Email:</label>
          <argon-input
            id="email"
            type="text"
            name="email"
            size="md"
            v-model="agentData.email"
          />
        </div>
        <div v-if="!isEditMode" class="mb-3">
          <label for="password-input" class="form-control-label"
            >Password:</label
          >
          <argon-input
            id="password"
            type="password"
            name="password"
            size="md"
            v-model="agentData.password"
          />
        </div>
        <div class="mb-3">
          <label for="firstname-input" class="form-control-label"
            >First Name:</label
          >
          <argon-input
            id="firstname"
            type="text"
            name="firstname"
            size="md"
            v-model="agentData.firstName"
          />
        </div>
        <div class="mb-3">
          <label for="lastname-input" class="form-control-label"
            >Last Name:</label
          >
          <argon-input
            id="lastname"
            type="text"
            name="lastname"
            size="md"
            v-model="agentData.lastName"
          />
        </div>
        <div class="mb-3">
          <label for="lastname-input" class="form-control-label">Agency:</label>
          <select
            id="role-select"
            class="form-select"
            v-model="selectedAgencyId"
          >
            <option
              v-for="agency in data.filter((a) => a.accountId !== 4)"
              :key="agency.accountId"
              :value="agency.accountId"
              :selected="agency.accountId === 46"
            >
              {{ agency.userName }}
            </option>
          </select>
        </div>
        <div class="mb-3">
          <label for="lastname-input" class="form-control-label">Role:</label>
          <select id="role-select" class="form-select" v-model="agentData.role">
            <option value="AGENT-ADMIN">Admin</option>
            <option value="AGENT-CLICKER">Clicker</option>
            <option value="AGENT-READER">Reader</option>
            <!-- Add more role options as needed -->
          </select>
        </div>
        <div class="text-left">
          <argon-button
            class="border-radius-2xl"
            variant="gradient"
            color="dark"
            :disabled="isLoading"
            size="md"
          >
            {{
              isLoading
                ? isEditMode
                  ? "Updating..."
                  : "Creating..."
                : isEditMode
                  ? "Update User"
                  : "Create User"
            }}
          </argon-button>
        </div>
      </form>
    </div>
  </div>
</template>
