<script setup>
import { onBeforeMount, computed, onMounted, ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import SmsJobsTable from "./SMSReportsTable.vue";
import MDRTable from "./MDRTable.vue";

const body = document.getElementsByTagName("body")[0];

const toast = useToast();
const store = useStore();
const isRTL = computed(() => store.state.app.isRTL);
const loading = computed(() => store.state.loading);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"]?.role);

const identities = computed(() => {
  const data = store.getters["identityData/getIdentities"];

  const result = data.filter((itm) => {
    // Check if accountInfo.value is an empty object
    if (userRole.value !== "ADMIN") return true;
    if (!accountInfo.value || Object.keys(accountInfo.value).length === 0) {
      return true;
    }
    return itm.userName === accountInfo.value.userName;
  });

  return [{ identityId: "", identityName: "All Identities" }, ...result];
});

const jobs = computed(() => store.getters["jobData/getJobs"]);
const mdrs = computed(() => store.getters["jobData/getMDRs"]);
const userList = computed(() => {
  const data = store.getters["user/getUsers"];
  return [
    { accountId: "", userName: "All Agencies" }, // New first item
    ...data, // Spread the existing identities
  ];
});
const activeTab = ref(0);
const tabs = ref([{ title: "P2P SMS Jobs" }, { title: "MDR Reports" }]);
const selectedJobLists = ref([]);
const dateRange = ref(null);
const startDate = ref(null);
const accountInfo = ref({});
const endDate = ref(null);
const jobSearch = ref("");
const selectedIdentity = ref("");
const selectedStatus = ref("");
const selectedSendType = ref("");
const isLoading = ref(false);
const displayDateRange = computed(() => {
  if (dateRange.value && dateRange.value.length === 2) {
    const [start, end] = dateRange.value;
    return `${formatDate(start)} - ${formatDate(end)}`;
  }
  return "Select Date Range";
});
const selectedIdentityName = computed(() => {
  if (!identities.value || !selectedIdentity.value) return "Identity";
  const identity = identities.value.find(
    (itm) => itm?.identityId === selectedIdentity.value
  );
  return identity?.identityName ?? "All Identities";
});

const filteredJobs = computed(() => {
  return jobs.value.filter((job) => {
    const matchesSearch =
      !jobSearch.value ||
      job.name.toLowerCase().includes(jobSearch.value.toLowerCase());
    const matchesStatus =
      !selectedStatus.value ||
      job.status.toLowerCase() == selectedStatus.value.toLowerCase();
    const matchesIdentity =
      !selectedIdentity.value || job.identityId == selectedIdentity.value;
    const matchesSendType =
      !selectedSendType.value ||
      job.jobType.toLowerCase() == selectedSendType.value.toLowerCase();
    const matchesDateRange = isWithinDateRange(job.startDate, dateRange.value);
    const agency =
      !accountInfo.value.accountId ||
      job.accountId == accountInfo.value.accountId;
    return (
      matchesSearch &&
      matchesStatus &&
      matchesIdentity &&
      matchesSendType &&
      matchesDateRange &&
      agency
    );
  });
});

const isValidDateRange = computed(() => {
  // Check if both dates are selected
  if (!startDate.value || !endDate.value) return false;

  const start = new Date(startDate.value);
  const end = new Date(endDate.value);
  const today = new Date();

  // Reset time part for today's date to compare only dates
  today.setHours(0, 0, 0, 0);

  // Check conditions:
  // 1. End date is after or equal to start date
  // 2. Both dates are before or equal to today
  return end >= start && start <= today && end <= today;
});

// Helper function to get max date for datepicker
const maxSelectableDate = computed(() => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
});

const statuses = ref([
  {
    id: "all",
    name: "All",
    value: false,
  },
  {
    id: "completed",
    name: "Completed",
    value: false,
  },
  {
    id: "active",
    name: "Active",
    value: false,
  },
  {
    id: "paused",
    name: "Paused",
    value: false,
  },
  {
    id: "pending",
    name: "Pending",
    value: false,
  },
  {
    id: "error",
    name: "Error",
    value: false,
  },
]);

const sendTypes = ref([
  {
    id: 0,
    name: "All",
    value: "",
  },
  {
    id: 1,
    name: "House Send",
    value: "House File",
  },
  {
    id: 2,
    name: "P2P Send",
    value: "P2P",
  },
  {
    id: 3,
    name: "Soapbox P2P Send",
    value: "Soapbox P2P",
  },
]);

const smsJobsHeadings = [
  { field: "Send Date", value: "startDate" },
  { field: "Identity", value: "identityId" },
  { field: "Job Type", value: "canUseMms" },
  { field: "Send Type", value: "jobType" },
  { field: "Job Name", value: "name" },
  { field: "Status", value: "status" },
  { field: "Sent", value: "messagesSent" },
  { field: "Received", value: "messagesOptOut" },
  { field: "Send Cost", value: "cost" },
  { field: "Action", value: null },
];

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
  filterJobs();
});

onMounted(async () => {
  if (userRole.value === "ADMIN") await store.dispatch("user/fetchUsers");
  if (userId.value) {
    fetchJobs();
  }

  fetchIdentity(userId.value);
});

const fetchIdentity = async (id) => {
  await store.dispatch("identityData/fetchIdentities", { userId: id });
};

const fetchJobs = async () => {
  isLoading.value = true;
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
  if (userRole.value === "ADMIN") await store.dispatch("jobData/fetchMDRs");
  isLoading.value = false;
};

const handleDateRangeChange = (newDateRange) => {
  if (newDateRange && Array.isArray(newDateRange)) {
    // Set start date to 12:01 AM
    const startDate = new Date(newDateRange[0]);
    startDate.setHours(0, 1, 0, 0);

    // Set end date to 11:59 PM
    const endDate = new Date(newDateRange[1]);
    endDate.setHours(23, 59, 0, 0);

    dateRange.value = [startDate.getTime(), endDate.getTime()];
  }
};

const handleStartDateChange = (date) => {
  const startDateTime = new Date(date);
  startDate.value = startDateTime;
  // If end date is before start date, reset it
  if (endDate.value && new Date(endDate.value) < new Date(date)) {
    endDate.value = null;
  }
};

const handleEndDateChange = (date) => {
  const endDateTime = new Date(date);
  endDate.value = endDateTime;
};

const formatDate = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString();
};

const isWithinDateRange = (date, range) => {
  if (!range || range.length !== 2) return true;
  const [start, end] = range;
  const jobDate = new Date(date);
  return jobDate >= start && jobDate <= end;
};

const filterJobs = async () => {
  const payload = {
    accountId: userId.value,
  };

  if (selectedIdentity.value) {
    payload.identityId = selectedIdentity.value.identityId;
  }

  if (selectedStatus.value) {
    payload.status = selectedStatus.value.name?.toLowerCase();
  }

  if (selectedSendType.value) {
    payload.jobType = selectedSendType.value.value;
  }

  if (jobSearch.value) {
    payload.query = jobSearch.value;
  }

  // resultJobs.value = jobs.value.filter((itm) => itm.name.includes(jobSearch.value))
  // await store.dispatch("jobData/filterJobs", payload);
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity.identityId;
  // await filterJobs();
};

const handleJobSearch = async () => {
  await filterJobs();
};

const onStatusChange = async (status) => {
  if (status.name === "All") {
    selectedStatus.value = "";
  } else {
    selectedStatus.value = status.name;
  }
  // await filterJobs();
};

const onSendTypeChange = async (sendType) => {
  console.log(sendType);
  selectedSendType.value = sendType.value;
  // await filterJobs();
};

const onDelete = async () => {
  try {
    await store.dispatch("jobData/deleteJob", selectedJobLists.value);
    console.log("DNCs deleted successfully");
    // Clear the selection after successful deletion
    selectedJobLists.value = [];
    // You might want to refresh your DNC list here or emit an event to the parent component
  } catch (error) {
    console.error("Error deleting DNCs:", error);
  }
  isLoading.value = true;
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
  isLoading.value = false;
};

const onJobSelect = (selected, e) => {
  e.preventDefault();
  if (e.target.checked) {
    if (!selectedJobLists.value.includes(selected.jobId)) {
      selectedJobLists.value.push(selected.jobId);
    }
  } else {
    selectedJobLists.value = selectedJobLists.value.filter(
      (id) => id !== selected.jobId
    );
  }
};

const onClickDelete = async (id) => {
  try {
    await store.dispatch("jobData/deleteJob", [id]);
    console.log("DNCs deleted successfully");
    // Clear the selection after successful deletion
    selectedJobLists.value = [];
    // You might want to refresh your DNC list here or emit an event to the parent component
  } catch (error) {
    console.error("Error deleting DNCs:", error);
  }
  isLoading.value = true;
  await store.dispatch("jobData/fetchJobs", { accountId: userId.value });
  isLoading.value = false;
};

const formatDisplayDate = (date) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const requestReport = async () => {
  if (!isValidDateRange.value) {
    toast.error("Please select a valid date range");
    return;
  }

  try {
    await store.dispatch("jobData/requestMDR", {
      startDate: startDate.value.toISOString().split("T")[0],
      endDate: endDate.value.toISOString().split("T")[0],
    });

    toast.success("Report generation initiated");
    fetchJobs();

    // Reset dates on success
    startDate.value = null;
    endDate.value = null;
  } catch (error) {
    console.log(error);

    toast.error("Failed to request report");
  }
};

const calculateTextLength = (htmlString) => {
  // Remove HTML tags
  const textOnly = htmlString.replace(/<[^>]*>/g, "");

  // Remove extra whitespace and newlines
  const trimmedText = textOnly.replace(/\s+/g, " ").trim();

  // Return the length
  return Math.ceil(trimmedText.length / 160);
};

const onAgencyChange = async (userInfo) => {
  accountInfo.value = userInfo;
  selectedIdentity.value = "";
};
</script>
<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-12">
            <div class="d-flex mb-4">
              <div
                class="pe-md-3 d-flex align-items-center"
                :class="isRTL ? 'me-md-0' : 'ms-md-0'"
                v-if="activeTab === 0"
              >
                <search-bar v-model="jobSearch" @input="handleJobSearch" />
              </div>
              <argon-dropdown class="me-1" v-if="activeTab === 0">
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    {{ selectedStatus || "Status" }}
                  </argon-button>
                </template>
                <a
                  v-for="(status, index) in statuses"
                  class="dropdown-item d-flex align-items-center"
                  href="#"
                  :key="index"
                  @click="onStatusChange(status)"
                >
                  <!-- <input type="checkbox" :id="status.id" :name="status.name" :checked="status.value" /> -->
                  <label :for="status.name" class="mb-0">{{
                    status.name
                  }}</label>
                </a>
              </argon-dropdown>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 0"
              >
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedSendType || "Send Type" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(sendType, index) in sendTypes"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    @click="onSendTypeChange(sendType)"
                  >
                    <!-- <input type="checkbox" :id="sendType.id" :name="sendType.name" /> -->
                    <label :for="sendType.name" class="mb-0">{{
                      sendType.name
                    }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 0"
              >
                <argon-dropdown>
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ displayDateRange || "Send Date Range" }}
                    </argon-button>
                  </template>
                  <VueDatePicker
                    v-model="dateRange"
                    :range="{ partialRange: false }"
                    :enable-time-picker="false"
                    :model-type="'timestamp'"
                    @update:modelValue="handleDateRangeChange"
                  />
                </argon-dropdown>
              </div>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 0 && userRole === 'ADMIN'"
              >
                <argon-dropdown class="me-1">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ accountInfo?.userName || "Agency" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(userinfo, index) in userList"
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    :key="index"
                    @click="onAgencyChange(userinfo)"
                  >
                    <label :for="userinfo.userName" class="mb-0">{{
                      userinfo.userName
                    }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 0"
              >
                <argon-dropdown
                  @prevent-disabled="Object.keys(accountInfo).length === 0"
                >
                  <template v-slot:title>
                    <argon-button
                      color="light"
                      class="dropdown-toggle"
                      :disabled="Object.keys(accountInfo).length === 0"
                    >
                      {{ selectedIdentityName }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 1"
              >
                <argon-dropdown>
                  <template v-slot:title>
                    <argon-button
                      color="light"
                      class="dropdown-toggle"
                      :disabled="loading"
                    >
                      {{ formatDisplayDate(startDate) || "Start Date" }}
                    </argon-button>
                  </template>
                  <VueDatePicker
                    v-model="startDate"
                    @update:modelValue="handleStartDateChange"
                    :max-date="maxSelectableDate"
                    :disabled-dates="disabledDates"
                  />
                </argon-dropdown>
              </div>
              <div
                class="d-flex align-items-center me-3"
                v-if="activeTab === 1"
              >
                <argon-dropdown>
                  <template v-slot:title>
                    <argon-button
                      color="light"
                      class="dropdown-toggle"
                      :disabled="loading"
                    >
                      {{ formatDisplayDate(endDate) || "End Date" }}
                    </argon-button>
                  </template>
                  <VueDatePicker
                    v-model="endDate"
                    @update:modelValue="handleEndDateChange"
                    :min-date="startDate"
                    :max-date="maxSelectableDate"
                    :disabled-dates="disabledDates"
                  />
                </argon-dropdown>
              </div>
              <argon-button
                color="light"
                @click="fetchJobs"
                :disabled="isLoading"
              >
                <i class="fas fa-refresh text-sm"></i>
                {{ "Refresh" }}
              </argon-button>
              <div class="ms-auto">
                <argon-button
                  v-if="userRole === 'ADMIN' && activeTab === 1"
                  color="primary"
                  :disabled="loading || !isValidDateRange"
                  @click="requestReport"
                >
                  <template v-if="loading">
                    <i class="fas fa-spinner fa-spin mr-2"></i>
                    Requesting...
                  </template>
                  <template v-else> Generate Report </template>
                </argon-button>
                <argon-button
                  v-if="selectedJobLists.length > 0 && userRole === 'ADMIN'"
                  color="warning"
                  size="md"
                  class="border-radius-2xl ms-2"
                  @click="onDelete()"
                >
                  Delete {{ selectedJobLists.length }} Job Lists
                </argon-button>
              </div>
            </div>
            <div class="w-full">
              <div class="d-flex align-items-center me-3">
                <!-- Simplified parent container -->
                <div class="tab-container" v-if="userRole === 'ADMIN'">
                  <button
                    v-for="(tab, index) in tabs"
                    :key="index"
                    @click="activeTab = index"
                    :class="[
                      'tab-button px-4 py-2',
                      activeTab === index ? 'active' : '',
                    ]"
                  >
                    {{ tab.title }}
                  </button>
                </div>

                <div class="d-flex ms-auto gap-3">
                  <!-- Metrics container -->
                  <div class="whitespace-nowrap">
                    <span style="color: #f8f9fa">Total Sent: </span>
                    <span style="color: #f8f9fa">{{
                      filteredJobs
                        .reduce(
                          (acc, curr) => acc + parseInt(curr.messagesSent),
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })
                    }}</span>
                  </div>
                  <div class="whitespace-nowrap">
                    <span style="color: #f8f9fa">Total Received: </span>
                    <span style="color: #f8f9fa">{{
                      filteredJobs
                        .reduce(
                          (acc, curr) => acc + parseInt(curr.messagesOptOut),
                          0
                        )
                        .toLocaleString("en-US", {
                          maximumFractionDigits: 0,
                        })
                    }}</span>
                  </div>
                  <div class="whitespace-nowrap">
                    <span style="color: #f8f9fa">Total Cost: </span>
                    <span style="color: #f8f9fa"
                      >${{
                        filteredJobs
                          .reduce(
                            (acc, curr) =>
                              acc +
                              parseInt(
                                (parseInt(curr.messagesSent) *
                                  (curr.initialTemplateMedia
                                    ? 1
                                    : calculateTextLength(
                                        curr?.initialTemplateMessage
                                      )) +
                                  parseInt(curr.messagesOptOut)) *
                                  (curr?.canUseMms
                                    ? curr?.imgMmsRate
                                    : curr?.smsRate)
                              ),
                            0
                          )
                          .toLocaleString("en-US", {
                            maximumFractionDigits: 0,
                          })
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <!-- First Table -->
                <div
                  v-show="activeTab === 0"
                  class="transition-all duration-300"
                >
                  <sms-jobs-table
                    :headings="smsJobsHeadings"
                    :data="filteredJobs"
                    :itemClick="onJobSelect"
                    :onClickDelete="onClickDelete"
                    :isLoading="isLoading"
                  />
                </div>

                <!-- Second Table -->
                <div
                  v-show="activeTab === 1"
                  class="transition-all duration-300"
                >
                  <m-d-r-table
                    :headings="smsJobsHeadings"
                    :data="mdrs"
                    :itemClick="onJobSelect"
                    :onClickDelete="onClickDelete"
                    :isLoading="isLoading"
                    v-if="userRole === 'ADMIN'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.tab-container {
  display: inline-flex;
  background: #f8f9fa; /* Light gray background */
  border-radius: 1rem;
  padding: 3px; /* Added padding for inner spacing */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.tab-button {
  padding: 8px 24px;
  border: none;
  color: #495057; /* Professional dark gray for text */
  font-size: 14px;
  font-weight: 500; /* Medium weight for better readability */
  cursor: pointer;
  border-radius: 1rem;
  position: relative;
  transition: all 0.2s ease;
  background: transparent;
}

.tab-button:hover:not(.active) {
  background: rgba(0, 0, 0, 0.04); /* Subtle hover effect */
}

.tab-button.active {
  background-color: #e9ecef; /* More professional gray for active state */
  color: #212529; /* Darker text for active state */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.tab-content {
  margin-top: 20px;
}
</style>
