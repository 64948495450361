// store/modules/phoneListData.js
import axios from 'axios';

const state = {
  phoneLists: [],
  suppressionLists: [],
  selectedPhoneList: {},
  selectedSuppressionList: {},
  loading: false,
  error: null,
};

const mutations = {
  setPhoneLists(state, phoneLists) {
    state.phoneLists = phoneLists;
  },
  setSuppressionLists(state, suppresssionLists) {
    state.suppressionLists = suppresssionLists;
  },
  setSelectedPhoneList(state, phoneList) {
    state.selectedPhoneList = phoneList;
  },
  setSelectedSuppressionList(state, suppressionList) {
    state.selectedSuppressionList = suppressionList;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, error) {
    state.error = error;
  },
};

const actions = {
  async fetchPhoneLists({ commit }, { userId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/phone_lists?account_id=${userId}`
      );
      commit('setPhoneLists', response.data);
      commit('setError', null);
    } catch (error) {
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async searchPhoneLists({ commit }, { query, userId, identityId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/phone_lists?query=${query}&account_id=${userId}&identity_id=${identityId}`
      );
      commit('setPhoneLists', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error searching phoneLists:', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async filterPhoneListByIdentity({ commit }, { userId, identityId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/phone_lists?account_id=${userId}&identity_id=${identityId}`
      );
      commit('setPhoneLists', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error filtering phone list', error);
    } finally {
      commit('setLoading', false);
    }
  },
  async addPhoneList({ commit }, payload) {
    commit('setLoading', true);
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/phone_lists`,
        payload
      );
      if (payload.listType === 'Suppression') {
        commit('setSelectedSuppressionList', response.data);
      } else {
        commit('setSelectedPhoneList', response.data);
      }
      commit('setError', null);
    } catch (error) {
      console.error('Error adding Phone List', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async fetchSuppressionLists({ commit }, { userId }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/phone_lists?account_id=${userId}&list_type=Suppression`
      );
      commit('setSuppressionLists', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error fetching suppression lists', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async searchSuppressionLists({ commit }, { userId, query }) {
    commit('setLoading', true);
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_URL}/phone_lists?account_id=${userId}&query=${query}&list_type=Suppression`
      );
      commit('setSuppressionLists', response.data);
      commit('setError', null);
    } catch (error) {
      console.error('Error searching suppression lists', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
  async deletePhoneLists({ commit }, payload) {
    commit('setLoading', true);
    try {
      await axios.delete(`${process.env.VUE_APP_API_URL}/phone_lists/`, {
        data: { ids: payload },
      });
      commit('setError', null);
    } catch (error) {
      console.error('Error adding DNC', error);
      commit('setError', error.message);
    } finally {
      commit('setLoading', false);
    }
  },
};

const getters = {
  getPhoneLists: (state) => state.phoneLists,
  getSuppressionLists: (state) => state.suppressionLists,
  getSelectedPhoneList: (state) => state.selectedPhoneList,
  getSelectedSuppressionList: (state) => state.selectedSuppressionList,
  isLoading: (state) => state.loading,
  getError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
