<script setup>
import { computed, onBeforeMount, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { convertTimestampToLocal } from "@/utils";

const body = document.getElementsByTagName("body")[0];
const router = useRouter();
const store = useStore();
const userId = computed(() => store.getters["user/currentUser"].id);
const job = computed(() => store.getters["jobData/getSelectedJob"]);
const identities = computed(() => store.getters["identityData/getIdentities"]);

const identityName = computed(() => {
  const found = identities.value?.find(
    (item) => item.identityId === job.value.identityId
  );
  return found?.identityName;
});

const agentList = computed(() => {
  return job?.value?.agents?.map((agent) => `${agent.firstName} ${agent.lastName}`);
});

onBeforeMount(() => {
  store.state.app.hideConfigurationButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(async () => {
  await store.dispatch("agentData/fetchAgents", { userId: userId.value });
});

const onMessagesSentClick = () => {
  router.push(`${router.currentRoute.value.path}/reports`);
};

const onRepliesReceivedClick = () => {
  //
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-2">P2P Details</h2>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <div>
                  <p class="text-sm mb-0">Identity of the Sender:</p>
                  <p class="text-lg font-weight-bold">{{ identityName }}</p>
                </div>
                <div>
                  <p class="text-sm mb-0">Send Type:</p>
                  <p class="text-lg font-weight-bold">{{ job.jobType }}</p>
                </div>
                <div>
                  <p class="text-sm mb-0">Job Name:</p>
                  <p class="text-lg font-weight-bold">{{ job.name }}</p>
                </div>
                <div>
                  <p class="text-sm mb-0">Job ID Number:</p>
                  <p class="text-lg font-weight-bold">{{ job.jobId }}</p>
                </div>
                <div>
                  <p class="text-sm mb-0">Send Date/Time:</p>
                  <p class="text-lg font-weight-bold">{{ convertTimestampToLocal(job.startDate) }}</p>
                </div>
                <div>
                  <p class="text-sm mb-0">Agents:</p>
                  <p
                    v-for="(agent, index) in agentList"
                    :key="index"
                    class="text-lg font-weight-bold"
                  >
                    {{ agent }}
                  </p>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-12">
                    <div class="mb-3 card bg-primary">
                      <div class="p-3 card-body d-flex flex-column align-items-center">
                        <p class="text-4xl font-weight-bold text-white">
                          {{ job.agents?.length || 0 }}
                        </p>
                        <p class="text-lg text-white">Assigned Agents</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-12">
                    <div class="mb-3 card bg-warning">
                      <div class="p-3 card-body d-flex flex-column align-items-center">
                        <p class="text-4xl font-weight-bold text-white">
                          {{ job.leadsRemaining || 0 }}
                        </p>
                        <p class="text-lg text-white">Remaining Leads</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-12">
                    <div class="mb-3 card bg-danger" @click.prevent="onMessagesSentClick">
                      <div class="p-3 card-body d-flex flex-column align-items-center">
                        <p class="text-4xl font-weight-bold text-white">
                          {{ job.messagesSent || 0 }}
                        </p>
                        <p class="text-lg text-white">Messages Sent</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 col-12">
                    <div
                      class="mb-3 card bg-info"
                      @click.prevent="onRepliesReceivedClick"
                    >
                      <div class="p-3 card-body d-flex flex-column align-items-center">
                        <p class="text-4xl font-weight-bold text-white">
                          {{ (+job.messagesOptOut + (+job.messagesNonOptOut)) || 0 }}
                        </p>
                        <p class="text-lg text-white">Replies Received</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
