<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
const isLoading = ref(false);

const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const isRTL = computed(() => store.state.isRTL);

const files = ref([]);
const uploaded = ref(false);

const toggleAddMediaPanel = () => store.commit("mediaData/toggleAddMediaPanel");

const isValid = computed(() => {
  const noIdentityFiles = files.value.filter((item) => item.identity == null);
  if (noIdentityFiles.length == 0) return true;
  return false;
});

const onUploadClick = () => {
  document.getElementById("fileInput").click();
};

const onFileUpload = ($event) => {
  const target = $event?.target;
  if (target && target.files) {
    const fileList = Array.from(target.files);
    const filesArray = fileList.map((file) => ({
      file: file,
      title: file.name,
    }));
    files.value = filesArray;
    if (files.value.length > 0) {
      uploaded.value = true;
    }
  }
};

const onRemove = (index) => {
  files.value.splice(index, 1);
  if (files.value.length == 0) {
    uploaded.value = false;
  }
};

const onUploadSubmit = async () => {
  isLoading.value = true;
  for (const fileItem of files.value) {
    try {
      let mediaType = null;
      if (fileItem.file.type?.includes("image")) {
        mediaType = "IMAGE";
      }
      if (fileItem.file.type?.includes("video")) {
        mediaType = "VIDEO";
      }
      const formData = new FormData();
      formData.append("account_id", userId.value);
      formData.append("title", fileItem.title);
      formData.append("identity_id", fileItem.identity.identityId);
      formData.append("file", fileItem.file);
      formData.append("media_type", mediaType);

      // Make API call to upload the file
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/media/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      /* Consider later
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}/media/upload-to-bandwidth`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      */

      if (response.status === 200) {
        console.log("File uploaded successfully:", fileItem.title);
        store.dispatch("mediaData/fetchMedia", { userId: userId.value });
        uploaded.value = false;
        isLoading.value = false;
        files.value = [];
        toggleAddMediaPanel();
      } else {
        console.error("Failed to upload file:", fileItem.title);
      }
    } catch (error) {
      console.error("Error uploading file:", fileItem.title, error);
    }
  }
};
</script>

<template>
  <div class="fixed-plugin">
    <div class="shadow-lg card overflow-scroll p-4">
      <div class="pb-0">
        <div class="float-start">
          <h5 class="">Media Upload Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAddMediaPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div v-if="!uploaded" class="">
          <label for="upload-file-input" class="form-control-label"
            >File:</label
          >
          <argon-input
            ref="file"
            type="file"
            id="fileInput"
            @change="(e) => onFileUpload(e)"
            accept="image/*, video/*"
            :multiple="true"
          />
          <argon-button @click.prevent="onUploadClick()">Upload</argon-button>
        </div>
        <div v-if="uploaded">
          <div class="table p-0 mt-4">
            <table class="table align-items-center justify-content-center mb-0">
              <thead>
                <tr>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Title
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  >
                    Identity
                  </th>
                  <th
                    class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in files"
                  :key="index"
                  class="align-items-center"
                >
                  <td class="">
                    <argon-input
                      type="text"
                      size="md"
                      v-model="item.title"
                      class="mb-0"
                    />
                  </td>
                  <td class="">
                    <argon-dropdown class="ms-3">
                      <template v-slot:title>
                        <argon-button color="light" class="dropdown-toggle">
                          {{ item.identity?.identityName || "Identity" }}
                        </argon-button>
                      </template>
                      <a
                        v-for="(identity, iIndex) in identities"
                        :key="iIndex"
                        class="dropdown-item d-flex align-items-center"
                        @click="item.identity = identity"
                      >
                        <label class="mb-0">{{
                          identity.displayName || identity.identityName
                        }}</label>
                      </a>
                    </argon-dropdown>
                  </td>
                  <td>
                    <argon-button
                      color="warning"
                      size="sm"
                      class="action-btn rounded-circle ms-auto px-2"
                      @click="onRemove(index)"
                    >
                      <i
                        class="fas fa-trash-alt text-xs text-white"
                        aria-hidden="true"
                      ></i>
                    </argon-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <argon-button :disabled="!isValid" class="" @click="onUploadSubmit">
            Submit
          </argon-button>
        </div>
      </div>
      <div v-if="isLoading" class="card text-center my-4 spinner-center">
        <div class="spinner-border text-primary loading-spinner" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">Loading data...</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.spinner-center {
  align-items: center;
}
</style>
