<script setup>
import { ref, computed, onBeforeMount, onMounted } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import SearchBar from "@/components/PageLayout/SearchBar.vue";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import { getFormattedDate } from "@/utils";
import Tooltip from "@/components/Tooltip/index.vue";
import { useToast } from "vue-toast-notification";
import axios from "axios";

const body = document.getElementsByTagName("body")[0];

const toast = useToast();
const store = useStore();
const phoneLists = computed(() => store.getters["phoneListData/getPhoneLists"]);
const identities = computed(() => {
  const data = store.getters["identityData/getIdentities"];

  const result = data.filter((itm) => {
    // Check if accountInfo.value is an empty object
    if (userRole.value !== "ADMIN") return true;
    if (!accountInfo.value || Object.keys(accountInfo.value).length === 0) {
      return true;
    }
    return itm.userName === accountInfo.value.userName;
  });

  return [{ identityId: "", identityName: "All Identities" }, ...result];
});
const userList = computed(() => {
  const data = store.getters["user/getUsers"];
  return [
    { accountId: "", userName: "All Agencies" }, // New first item
    ...data, // Spread the existing identities
  ];
});
const loading = computed(() => store.getters["phoneListData/isLoading"]);
const error = computed(() => store.getters["phoneListData/getError"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const userRole = computed(() => store.getters["user/currentUser"].role);
const sortedData = computed(() => {
  if (!sortColumn.value) return filteredJobs.value;

  return [...phoneLists.value].sort((a, b) => {
    let aVal = a[sortColumn.value];

    let bVal = b[sortColumn.value];

    if (sortColumn.value === "uploadDate") {
      aVal = new Date(a.createdAt);
      bVal = new Date(b.createdAt);
    }

    if (aVal < bVal) return sortDirection.value === "asc" ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === "asc" ? 1 : -1;
    return 0;
  });
});

const filteredJobs = computed(() => {
  return phoneLists.value.filter((item) => {
    const matchesSearch =
      !phoneListSearch.value ||
      item.listName.toLowerCase().includes(phoneListSearch.value.toLowerCase());
    const matchesIdentity =
      !selectedIdentity.value || item.identityId == selectedIdentity.value;
    const agency =
      !accountInfo.value.accountId ||
      item.accountId == accountInfo.value.accountId;
    return matchesSearch && matchesIdentity && agency;
  });
});

const selectedIdentityName = computed(() => {
  if (!identities.value || !selectedIdentity.value) return "Identity";
  const identity = identities.value.find(
    (itm) => itm?.identityId === selectedIdentity.value
  );
  return identity?.identityName ?? "All Identities";
});

const selectedPhoneLists = ref([]);
const selectedIdentity = ref(null);
const phoneListSearch = ref("");
const accountInfo = ref({});

const sortColumn = ref("");
const sortDirection = ref("asc");
const currentPage = ref(1);
const itemsPerPage = ref(10);
const showDeleteModal = ref(false);
const isDownloading = ref("");

const deleteItem = (id) => {
  showDeleteModal.value = true;
  selectedPhoneLists.value = [id];
};
const itemsPerPageOptions = [5, 10, 25, 50];

const getIdentityById = (id) => {
  return identities.value.find((itm) => itm.identityId == id) || null;
};

const sort = (column) => {
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
  } else {
    sortColumn.value = column;
    sortDirection.value = "asc";
  }
};

const totalPages = computed(() =>
  Math.ceil(sortedData.value.length / itemsPerPage.value)
);

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;

  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers

  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }

  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }

  // Add ellipsis if necessary
  if (startPage > 1) range.unshift("...");
  if (endPage < totalPageCount) range.push("...");

  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);

  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

onBeforeMount(() => {
  store.state.app.hideConfigButton = false;
  store.state.app.showNavbar = true;
  store.state.app.showSidenav = true;
  store.state.app.showFooter = true;
  body.classList.add("bg-gray-100");
});

onMounted(() => {
  store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });

  fetchIdentity(userId.value);
});

const fetchIdentity = async (id) => {
  await store.dispatch("identityData/fetchIdentities", { userId: id });
};

// const handlePhoneListSearch = () => {
//   store.dispatch("phoneListData/searchPhoneLists", {
//     userId: userId.value,
//     identityId: selectedIdentity.value?.identityId,
//     query: phoneListSearch.value,
//   });
// };

const onAdd = () => {
  store.commit("jobData/toggleAddPhoneListPanel");
};

const onDelete = async () => {
  try {
    await store.dispatch(
      "phoneListData/deletePhoneLists",
      selectedPhoneLists.value
    );
    console.log("PhoneLists deleted successfully");
    selectedPhoneLists.value = [];
    showDeleteModal.value = false;
  } catch (error) {
    console.error("Error deleting PhoneLists:", error);
  }
  await store.dispatch("phoneListData/fetchPhoneLists", {
    userId: userId.value,
  });
};

const onIdentitySelect = async (identity) => {
  selectedIdentity.value = identity.identityId;
  // await store.dispatch("phoneListData/filterPhoneListByIdentity", {
  //   userId: userId.value,
  //   identityId: identity.identityId,
  // });
};

const onAgencyChange = async (userInfo) => {
  accountInfo.value = userInfo;
  selectedIdentity.value = "";
};

const handleDownload = async (listId, fileUrl) => {
  if (isDownloading.value) return;

  isDownloading.value = listId;
  const s3Location = fileUrl.split("/").pop();

  try {
    const urlResponse = await axios.post(
      `${process.env.VUE_APP_API_URL}/phone_lists/phonenumbers-list/download`,
      { listId, s3Location }
    );

    if (!urlResponse.data.downloadUrl) {
      throw new Error("No download URL received");
    }

    window.location.href = urlResponse.data.downloadUrl;
  } catch (error) {
    console.error("Download error:", error);

    if (error.code === "ERR_NETWORK") {
      toast.error("Network error occurred. Please try again.");
    } else if (error.response) {
      const errorMessage =
        error.response.data?.message || "Failed to download report";
      toast.error(errorMessage);
    } else if (error.request) {
      toast.error("No response received from server");
    } else {
      toast.error(error.message || "Failed to download report");
    }
  } finally {
    isDownloading.value = "";
  }
};
</script>

<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <h2 class="text-white mb-4">Phone Lists</h2>
        <div class="card min-vh-50">
          <div class="card-body">
            <div class="d-flex align-items-center mb-4">
              <search-bar v-model="phoneListSearch" />
              <div
                class="d-flex align-items-center ms-4 me-3"
                v-if="userRole === 'ADMIN'"
              >
                <argon-dropdown class="me-1">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ accountInfo?.userName || "Agency" }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(userinfo, index) in userList"
                    class="dropdown-item d-flex align-items-center"
                    href="#"
                    :key="index"
                    @click="onAgencyChange(userinfo)"
                  >
                    <label :for="userinfo.userName" class="mb-0">{{
                      userinfo.userName
                    }}</label>
                  </a>
                </argon-dropdown>
              </div>
              <div class="d-flex align-items-center me-3">
                <argon-dropdown class="ms-3">
                  <template v-slot:title>
                    <argon-button color="light" class="dropdown-toggle">
                      {{ selectedIdentityName }}
                    </argon-button>
                  </template>
                  <a
                    v-for="(identity, index) in identities"
                    :key="index"
                    class="dropdown-item d-flex align-items-center"
                    @click="onIdentitySelect(identity)"
                  >
                    <label class="mb-0">{{ identity.identityName }}</label>
                  </a>
                </argon-dropdown>
              </div>

              <argon-button
                v-if="
                  userRole !== 'AGENT-READER' && userRole !== 'AGENT-CLICKER'
                "
                color="success"
                size="sm"
                class="action-btn rounded-circle ms-auto px-2"
                @click="onAdd()"
              >
                <i
                  class="fas fa-plus text-xs text-white"
                  aria-hidden="true"
                ></i>
              </argon-button>
            </div>
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">{{ error }}</div>
            <div v-else class="table-responsive p-0">
              <table
                class="table align-items-center justify-content-center mb-0"
              >
                <thead>
                  <tr>
                    <th
                      @click="sort('listName')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      List Name
                      <span v-if="sortColumn === 'listName'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      @click="sort('identityId')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Identity
                      <span v-if="sortColumn === 'identityId'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      @click="sort('listType')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      List Type
                      <span v-if="sortColumn === 'listType'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      @click="sort('uploadDate')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Upload<br />Date
                      <span v-if="sortColumn === 'uploadDate'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      @click="sort('leadsLoaded')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Leads<br />Loaded
                      <span v-if="sortColumn === 'leadsLoaded'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <!-- <th
                      @click="sort('leadsInvalid')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Leads<br />Remaining
                      <span v-if="sortColumn === 'leadsInvalid'">
                        {{ sortDirection === 'asc' ? '▲' : '▼' }}
                      </span>
                    </th> -->
                    <th
                      @click="sort('listState')"
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      List<br />State
                      <span v-if="sortColumn === 'listState'">
                        {{ sortDirection === "asc" ? "▲" : "▼" }}
                      </span>
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in paginatedData"
                    :key="index"
                    :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
                  >
                    <td class="w-20 px-4 text-wrap">{{ item.listName }}</td>
                    <td class="w-15 px-4 text-center">
                      {{ getIdentityById(item.identityId)?.identityName }}
                    </td>
                    <td class="w-15 px-4 text-center">{{ item.listType }}</td>
                    <td class="w-15 px-4 text-center">
                      {{ getFormattedDate(item.createdAt) }}
                    </td>
                    <td class="w-5 px-4 text-center">{{ item.leadsLoaded }}</td>
                    <td class="w-10 px-4 text-center">{{ item.listState }}</td>
                    <td class="px-2 text-center">
                      <Tooltip text="Download Report">
                        <i
                          v-if="
                            userRole === 'AGENT-ADMIN' || userRole === 'ADMIN'
                          "
                          :class="[
                            'fas p-2 text-sm',
                            isDownloading === item.listId
                              ? 'fa-spinner fa-spin text-gray-400'
                              : 'fa-download text-primary opacity-10 cursor-pointer hover:opacity-100',
                          ]"
                          @click.prevent="
                            handleDownload(item.listId, item.fileUrl)
                          "
                        ></i>
                      </Tooltip>
                      <Tooltip text="Delete phone list">
                        <i
                          v-if="
                            userRole === 'AGENT-ADMIN' || userRole === 'ADMIN'
                          "
                          class="fas fa-trash-can text-primary text-sm opacity-10 margin-left"
                          @click.prevent="deleteItem(item.listId)"
                        ></i>
                      </Tooltip>
                    </td>
                  </tr>
                </tbody>
              </table>
              <DeleteConfirmationModal
                :isOpen="showDeleteModal"
                content="Do you want to delete this item?"
                itemName="Media"
                @confirm="onDelete"
                @cancel="showDeleteModal = false"
              />
              <div
                v-if="totalPages > 1"
                class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5"
              >
                <div>
                  <span class="me-2">Rows per page:</span>
                  <select v-model="itemsPerPage" @change="resetPage">
                    <option
                      v-for="option in itemsPerPageOptions"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="pagination-nav">
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="goToFirstPage"
                    :disabled="currentPage === 1"
                    title="First Page"
                  >
                    &lt;&lt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="prevPage"
                    :disabled="currentPage === 1"
                    title="Previous Page"
                  >
                    &lt;
                  </button>
                  <template
                    v-for="pageNum in visiblePageNumbers"
                    :key="pageNum"
                  >
                    <button
                      v-if="pageNum !== '...'"
                      class="btn btn-sm me-1"
                      :class="
                        pageNum === currentPage
                          ? 'btn-primary'
                          : 'btn-secondary'
                      "
                      @click="goToPage(pageNum)"
                    >
                      {{ pageNum }}
                    </button>
                    <span v-else class="mx-1">...</span>
                  </template>
                  <button
                    class="btn btn-sm btn-secondary me-1"
                    @click="nextPage"
                    :disabled="currentPage === totalPages"
                    title="Next Page"
                  >
                    &gt;
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    @click="goToLastPage"
                    :disabled="currentPage === totalPages"
                    title="Last Page"
                  >
                    &gt;&gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff; /* White for odd rows */
}

.margin-left {
  margin-left: 12px;
}
</style>
