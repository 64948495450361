<script setup>
import { ref, watch } from "vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  agentid: {
    type: String,
    default: "",
  },
  agentEmail: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["confirm", "cancel"]);
const $toast = useToast();
const showPassword = ref(false);
const store = useStore();

let password = "";
let agentId = "";
let resetEmail = "";

const isVisible = ref(props.isOpen);
watch(
  () => props.isOpen,
  (newValue) => {
    isVisible.value = newValue;
    resetEmail = props.agentEmail;
  }
);

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const handleConfirm = async () => {
  agentId = props.agentid;

  try {
    await store.dispatch("agentData/resetPassword", {
      agentId,
      password,
    });

    isVisible.value = false;
    $toast.success("Reset password successful!", { position: "top-right" });
  } catch (error) {
    console.error("Reset password error:", error);
  }
};

const handleCancel = () => {
  isVisible.value = false;
  setTimeout(() => emit("cancel"), 300);
};
</script>

<template>
  <Transition name="modal">
    <div v-if="isVisible" class="modal-overlay" @click.self="handleCancel">
      <div class="modal-container">
        <div class="modal-content">
          <h2 class="modal-title">Reset Password</h2>
          <div class="modal-form">
            <div class="form-group">
              <label for="newPassword">{{ resetEmail }}</label>
              <div class="mb-3 position-relative">
                <argon-input
                  id="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Password"
                  name="password"
                  size="lg"
                  v-model="password"
                />
                <button
                  type="button"
                  class="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0 pe-3"
                  @click="togglePassword"
                  :aria-label="showPassword ? 'Hide password' : 'Show password'"
                  style="z-index: 2"
                >
                  <i
                    class="fa"
                    :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                    style="color: #8392ab"
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-actions">
            <button class="btn btn-secondary" @click="handleCancel">
              Cancel
            </button>
            <button class="btn btn-primary" @click="handleConfirm">
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.position-relative {
  position: relative;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 400px;
  padding: 20px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: none;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 1rem;
  color: #666;
  font-weight: 500;
}

.form-input {
  padding: 8px 12px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
}

.form-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition:
    background-color 0.3s,
    transform 0.1s;
}

.btn:active {
  transform: scale(0.98);
}

.btn-secondary {
  background-color: #f3f4f6;
  color: #374151;
  border: 1px solid #d1d5db;
}

.btn-secondary:hover {
  background-color: #e5e7eb;
}

.btn-primary {
  background-color: #3b82f6;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #2563eb;
}

/* Transition animations */
.modal-enter-active,
.modal-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>
