<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import ArgonButton from "@/components/Core/ArgonButton.vue";
import ArgonDropdown from "@/components/Core/ArgonDropdown.vue";
import ArgonInput from "@/components/Core/ArgonInput.vue";

const store = useStore();
const identities = computed(() => store.getters["identityData/getIdentities"]);
const userId = computed(() => store.getters["user/currentUser"].id);
const isRTL = computed(() => store.state.isRTL);
const dncs = computed(() => store.getters["dncData/getDNCs"]);

const dncData = ref({});
const nameError = ref('')

const selectedIdentity = ref(null);
const states = ref([
  { code: "AL", name: "Alabama" },
  { code: "AK", name: "Alaska" },
  { code: "AZ", name: "Arizona" },
  { code: "AR", name: "Arkansas" },
  { code: "CA", name: "California" },
  { code: "CO", name: "Colorado" },
  { code: "CT", name: "Connecticut" },
  { code: "DE", name: "Delaware" },
  { code: "FL", name: "Florida" },
  { code: "GA", name: "Georgia" },
  { code: "HI", name: "Hawaii" },
  { code: "ID", name: "Idaho" },
  { code: "IL", name: "Illinois" },
  { code: "IN", name: "Indiana" },
  { code: "IA", name: "Iowa" },
  { code: "KS", name: "Kansas" },
  { code: "KY", name: "Kentucky" },
  { code: "LA", name: "Louisiana" },
  { code: "ME", name: "Maine" },
  { code: "MD", name: "Maryland" },
  { code: "MA", name: "Massachusetts" },
  { code: "MI", name: "Michigan" },
  { code: "MN", name: "Minnesota" },
  { code: "MS", name: "Mississippi" },
  { code: "MO", name: "Missouri" },
  { code: "MT", name: "Montana" },
  { code: "NE", name: "Nebraska" },
  { code: "NV", name: "Nevada" },
  { code: "NH", name: "New Hampshire" },
  { code: "NJ", name: "New Jersey" },
  { code: "NM", name: "New Mexico" },
  { code: "NY", name: "New York" },
  { code: "NC", name: "North Carolina" },
  { code: "ND", name: "North Dakota" },
  { code: "OH", name: "Ohio" },
  { code: "OK", name: "Oklahoma" },
  { code: "OR", name: "Oregon" },
  { code: "PA", name: "Pennsylvania" },
  { code: "RI", name: "Rhode Island" },
  { code: "SC", name: "South Carolina" },
  { code: "SD", name: "South Dakota" },
  { code: "TN", name: "Tennessee" },
  { code: "TX", name: "Texas" },
  { code: "UT", name: "Utah" },
  { code: "VT", name: "Vermont" },
  { code: "VA", name: "Virginia" },
  { code: "WA", name: "Washington" },
  { code: "WV", name: "West Virginia" },
  { code: "WI", name: "Wisconsin" },
  { code: "WY", name: "Wyoming" },
]);

const toggleAddDNCPanel = () => store.commit("dncData/toggleAddDNCPanel");

const onIdentitySelect = (identity) => {
  selectedIdentity.value = identity;
};

const validateName = () => {
  if (!dncData.value.name) {
    nameError.value = ''
    return
  }

  const isDuplicate = dncs.value.some(dnc => {
    const dncName = dnc.name?.toLowerCase()
    return dncName && dncName === dncData.value.name.toLowerCase()
  })

  if (isDuplicate) {
    nameError.value = 'This name already exists. Please choose a different name.'
  } else {
    nameError.value = ''
  }
}

const onAddPhone = async () => {
  const payload = {
    accountId: userId.value,
    dncType: "PHONE",
    identityId: selectedIdentity.value?.identityId,
    reason: dncData.value.phoneReason,
    value: dncData.value.phone,
    name: dncData.value.name
  };
  await store.dispatch("dncData/addDNC", payload);
  toggleAddDNCPanel();
  dncData.value = {};
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
};

const onFileUpload = () => {
  //
};

const onAddFile = () => {
  //
};

const onAddStateDNC = async () => {
  const payload = {
    accountId: userId.value,
    dncType: "STATE",
    identityId: selectedIdentity.value?.identityId,
    reason: "",
    value: dncData.value.stateDNC?.code,
    name: dncData.value.name
  };
  await store.dispatch("dncData/addDNC", payload);
  toggleAddDNCPanel();
  dncData.value = {};
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
};

const onAddAreaCode = async () => {
  const payload = {
    accountId: userId.value,
    dncType: "AREA-CODE",
    identityId: selectedIdentity.value?.identityId,
    reason: "",
    value: dncData.value.areaCode,
    name: dncData.value.name
  };
  await store.dispatch("dncData/addDNC", payload);
  toggleAddDNCPanel();
  dncData.value = {};
  await store.dispatch("dncData/fetchDNCs", { userId: userId.value });
};
</script>

<template>
  <div class="fixed-plugin max-h-[100vh]">
    <div class="shadow-lg card overflow-[100vh] p-4">
      <div class="pb-0">
        <div class="float-start">
          <h5 class="">DNC Create Manager</h5>
        </div>
        <div
          class="mt-1"
          @click="toggleAddDNCPanel"
          :class="isRTL ? 'float-start' : 'float-end'"
        >
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div>
        <div class="mb-3">
          <label>Identity:</label>
          <argon-dropdown class="">
            <template v-slot:title>
              <argon-button color="light" class="dropdown-toggle">
                {{ selectedIdentity?.identityName || "Identity" }}
              </argon-button>
            </template>
            <a
              v-for="(identity, index) in identities"
              :key="index"
              class="dropdown-item d-flex align-items-center"
              @click="onIdentitySelect(identity)"
            >
              <label class="mb-0">{{ identity.identityName }}</label>
            </a>
          </argon-dropdown>
        </div>
        <div>
          <label for="name-input" class="form-control-label">Name:</label>
          <argon-input
            id="name"
            type="text"
            name="name"
            size="md"
            v-model="dncData.name"
            @input="validateName"
          />
          <span v-if="nameError" class="error-message">{{ nameError }}</span>
        </div>
        <div class="mt-4">
          <h5>Manual Insert</h5>
          <div class="row">
            <div class="col-lg-9 col-12">
              <div>
                <label for="phone-input" class="form-control-label">Phone:</label>
                <argon-input
                  id="phone"
                  type="text"
                  name="phone"
                  size="md"
                  v-model="dncData.phone"
                />
              </div>
              <div>
                <label for="phone-reason" class="form-control-reason">Reason:</label>
                <argon-input
                  id="phoneReason"
                  type="text"
                  name="phoneReason"
                  size="md"
                  class="mb-0"
                  v-model="dncData.phoneReason"
                />
              </div>
            </div>
            <div class="col-lg-3 col-12 d-flex align-items-end justify-content-end">
              <argon-button
                :disabled="!selectedIdentity || !dncData.phone"
                class=""
                @click="onAddPhone"
              >
                Add
              </argon-button>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h5>File Upload</h5>
          <div class="row">
            <div class="col-lg-9 col-12">
              <div class="">
                <label for="upload-file-input" class="form-control-label">File:</label>
                <argon-input
                  type="file"
                  @change="onFileUpload"
                  accept="image/*, video/*"
                />
              </div>
              <div class="">
                <label for="file-upload-reason" class="form-control-label">Reason:</label>
                <argon-input
                  id="fileUploadReason"
                  type="text"
                  name="fileUploadReason"
                  size="md"
                  class="mb-0"
                  v-model="dncData.fileUploadReason"
                />
              </div>
            </div>
            <div class="col-lg-3 col-12 d-flex align-items-end justify-content-end">
              <argon-button :disabled="!selectedIdentity || !dncData.name" class="" @click="onAddFile">
                Add
              </argon-button>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h5>State DNC</h5>
          <div class="row">
            <div class="col-lg-9 col-12">
              <label for="state-dnc-input" class="form-control-label">State:</label>
              <argon-dropdown class="">
                <template v-slot:title>
                  <argon-button color="light" class="dropdown-toggle">
                    <span v-if="dncData.stateDNC"
                      >{{ dncData.stateDNC?.code }} - {{ dncData.stateDNC?.name }}</span
                    >
                    <span v-if="!dncData.stateDNC">State</span>
                  </argon-button>
                </template>
                <a
                  v-for="(item, index) in states"
                  :key="index"
                  class="dropdown-item d-flex align-items-center"
                  @click="dncData.stateDNC = item"
                >
                  <label class="mb-0">{{ item.code }} - {{ item.name }}</label>
                </a>
              </argon-dropdown>
            </div>
            <div class="col-lg-3 col-12 d-flex align-items-end justify-content-end">
              <argon-button
                :disabled="!selectedIdentity || !dncData.stateDNC || !dncData.name"
                class=""
                @click="onAddStateDNC"
              >
                Add
              </argon-button>
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h5>Area Code</h5>
          <div class="row">
            <div class="col-lg-9 col-12">
              <label for="area-code-input" class="form-control-label">Area Code:</label>
              <argon-input
                id="areaCodeInput"
                type="text"
                name="areaCodeInput"
                size="md"
                class="mb-0"
                v-model="dncData.areaCode"
              />
            </div>
            <div class="col-lg-3 col-12 d-flex align-items-end justify-content-end">
              <argon-button
                :disabled="!selectedIdentity || !dncData.areaCode || !dncData.name"
                class=""
                @click="onAddAreaCode"
              >
                Add
              </argon-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error-message {
  color: red;
  font-size: 0.8em;
}
</style>