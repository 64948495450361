<script setup>
import { ref, watch } from "vue";
import { Doughnut, Line, Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  BarElement,
} from "chart.js";

const props = defineProps({
  isLoading: {
    type: Boolean,
    required: true,
  },
  deliverChartData: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  deliverMessageCount: {
    type: Number,
    default: 0,
  },
});

// Reactive reference to store deliverChartData locally if needed
const localChartData = ref(props.deliverChartData);
const messageCount = ref(0);
const successfulMessageCount = ref(0);
const failedMessageCount = ref(0);
const pieDeliverOption = ref(null);
const pieErrorOption = ref(null);
const overallRate = ref(0);
const errorRate = ref(1);
const isChartVisible = ref(false);
const SMSData = ref([]);
const MMSData = ref([]);
const mmsSuccessRates = ref([]);
const smsSuccessRates = ref([]);
const trackChartData = ref(null);
const trackChartOption = ref(null);

// Data and Options for Line Chart
const chartData = ref(null);
const chartOptions = ref(null);

const PieDeliverData = ref({
  labels: [],
  datasets: [],
});

const PieErrorData = ref({
  labels: [],
  datasets: [],
});

// Watch the prop for changes
watch(
  () => props.deliverChartData,
  (newValue) => {
    // Update the local reference if necessary
    localChartData.value = newValue;

    if (localChartData.value && localChartData.value.totalMessageCount > 0) {
      isChartVisible.value = true;
    }

    // Ensure these values are properly updated
    MMSData.value = localChartData.value.mmsTypeMessage
      ? localChartData.value.mmsTypeMessage
      : [];
    SMSData.value = localChartData.value.smsTypeMessage
      ? localChartData.value.smsTypeMessage
      : [];

    messageCount.value = localChartData.value.totalMessageCount || 0;
    successfulMessageCount.value =
      localChartData.value.successfulMessageCount || 0;
    failedMessageCount.value = localChartData.value.failedMessageCount || 0;

    if (messageCount.value !== 0) {
      overallRate.value =
        ((successfulMessageCount.value / messageCount.value) * 100).toFixed(
          2
        ) || 0;
      errorRate.value =
        ((failedMessageCount.value / messageCount.value) * 100).toFixed(2) || 0;
    } else {
      overallRate.value = 0;
      errorRate.value = 0;
    }

    const documentStyle = getComputedStyle(document.documentElement);

    const styles = {
      text: documentStyle.getPropertyValue("--p-text-color"),
      textMuted: documentStyle.getPropertyValue("--p-text-muted-color"),
    };

    const setPieDeliverData = () => ({
      labels: ["Devlier", "Failed"],
      datasets: [
        {
          data: [successfulMessageCount, failedMessageCount],
          backgroundColor: ["rgba(1, 135, 99, 1)", "rgba(1, 135, 99, 0.5)"],
          hoverBackgroundColor: [
            "rgba(1, 135, 99, 0.5)",
            "rgba(1, 135, 99, 0.5)",
          ],
        },
      ],
    });

    const setPieDeliverOption = () => {
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          centerText: {
            // text: String(overallRate.value) + "%",
            text: `${overallRate.value}%`,
            color: "rgba(0, 0, 0, 0.6)", // Use the same color as the legend text
            fontSize: 25,
          },
        },
        cutout: "70%",
        radius: "100%",
      };
    };

    const setPieErrorData = () => ({
      labels: ["Failed", "Devlier"],
      datasets: [
        {
          data: [failedMessageCount, successfulMessageCount],
          // data: [3, 0],
          backgroundColor: [
            "rgba(251, 236, 234, 0.5)",
            "rgba(251, 236, 234, 0.5)",
          ],
          hoverBackgroundColor: [
            "rgba(251, 236, 234, 0.2)",
            "rgba(251, 236, 234, 0.2)",
          ],
        },
      ],
    });

    const setPieErrorOption = () => {
      return {
        responsive: true, // Ensures responsiveness
        maintainAspectRatio: false, // Allows custom height/width to be applied
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          centerText: {
            text: `${errorRate.value}%`,
            color: "rgba(0, 0, 0, 0.6)", // Use the same color as the legend text
            fontSize: 25,
          },
        },
        cutout: "70%",
        radius: "100%",
      };
    };

    PieDeliverData.value = setPieDeliverData();
    pieDeliverOption.value = setPieDeliverOption();

    PieErrorData.value = setPieErrorData();
    pieErrorOption.value = setPieErrorOption();

    // Get past 30 days
    const labels = getPastDays();

    mmsSuccessRates.value = calculateSuccessRate(MMSData.value, labels);
    smsSuccessRates.value = calculateSuccessRate(SMSData.value, labels);

    const filteredLabels = labels; // Don't filter, keep all 30 days

    chartData.value = {
      labels: filteredLabels, // All 30 days
      datasets: [
        {
          label: "MMS",
          data: mmsSuccessRates.value, // MMS success rates for all 30 days
          borderColor: "rgba(77, 158, 40, 0.5)",
          backgroundColor: "rgba(128, 128, 128, 0.2)",
          tension: 0,
        },
        {
          label: "SMS",
          data: smsSuccessRates.value, // SMS success rates for all 30 days
          borderColor: "rgba(123, 206, 255, 1)",
          backgroundColor: "rgba(128, 128, 128, 0.2)",
          tension: 0,
        },
      ],
    };

    chartOptions.value = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          labels: {
            color: styles.text,
          },
        },
        tooltip: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (context) => `${context.dataset.label}: ${context.raw}%`,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: styles.textMuted,
          },
          grid: {
            color: "rgba(128, 128, 128, 0.3)",
          },
        },
        y: {
          ticks: {
            color: styles.textMuted,
            callback: (value) => `${value}%`,
            stepSize: 25,
          },
          grid: {
            color: "rgba(128, 128, 128, 0.3)",
          },
          beginAtZero: true,
          suggestedMax: 100,
        },
      },
    };

    //Track Bart Chart
    if (
      localChartData.value.failedMessage &&
      localChartData.value.failedMessage.length > 0
    ) {
      const { labels, counts } = processFailedMessages(
        localChartData.value.failedMessage
      );

      trackChartData.value = {
        labels: labels,
        datasets: [
          {
            label: "",
            borderColor: "rgba(123, 206, 255, 1)",
            backgroundColor: "rgba(123, 206, 255, 0.6)",
            data: counts,
          },
        ],
      };

      // Update chart options to better display phone numbers
      trackChartOption.value = {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            labels: {
              color: styles.text,
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                return `Failed Messages: ${context.raw}`;
              },
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Number of Failed Messages",
              color: styles.textMuted,
            },
            ticks: {
              color: styles.textMuted,
              font: {
                weight: 500,
              },
              stepSize: 1,
            },
            grid: {
              color: "rgba(128, 128, 128, 0.3)",
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: styles.textMuted,
            },
            grid: {
              color: "rgba(128, 128, 128, 0.3)",
              drawBorder: false,
            },
          },
        },
      };
    }
  },
  { immediate: true } // Runs immediately after the watcher is set up
);

// Register Chart.js components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  BarElement,
  {
    id: "centerText",
    beforeDraw(chart) {
      const { width } = chart;
      const { height } = chart;
      const ctx = chart.ctx;
      const centerTextPlugin = chart.config.options.plugins.centerText;

      if (centerTextPlugin) {
        const { text, color, fontSize } = centerTextPlugin;

        ctx.save();
        ctx.font = `${fontSize || 20}px Arial`;
        ctx.fillStyle = color || "black";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(text, width / 2, height / 2);
        ctx.restore();
      }
    },
  }
);

function getPastDays() {
  const days = [];
  const today = new Date();
  for (let i = 29; i >= 0; i--) {
    const pastDay = new Date(today);
    pastDay.setDate(today.getDate() - i);
    const formattedDate = `${pastDay.getMonth() + 1}/${pastDay.getDate()}/${pastDay.getFullYear()}`;
    days.push(formattedDate);
  }
  return days;
}

// Preprocess database data to count messages per day and calculate success percentage
function calculateSuccessRate(dbData, labels) {
  const successRates = labels.map(() => 0); // Initialize success rates array with zeros
  const totalMessagesPerDay = new Array(labels.length).fill(0);
  const successfulMessagesPerDay = new Array(labels.length).fill(0);

  dbData.forEach((entry) => {
    const dbDate = new Date(entry.message_createdat);
    const formattedDate = `${dbDate.getMonth() + 1}/${dbDate.getDate()}/${dbDate.getFullYear()}`;
    const index = labels.indexOf(formattedDate); // Find matching label index
    if (index !== -1) {
      totalMessagesPerDay[index] += 1; // Increment total messages count for that day
      if (
        entry.message_status === "RECEIVED" ||
        entry.message_status === "SENT"
      ) {
        // Assuming 'RECEIVED' or 'SENT' status means successful
        successfulMessagesPerDay[index] += 1; // Increment successful message count
      }
    }
  });

  // Calculate success rate for each day and ensure 0% values are included
  successRates.forEach((_, index) => {
    if (totalMessagesPerDay[index] > 0) {
      const successRate = (
        (successfulMessagesPerDay[index] / totalMessagesPerDay[index]) *
        100
      ).toFixed(2);
      successRates[index] =
        successRate === "0.00" ? 0 : parseFloat(successRate); // Set to 0 if no successful messages
    } else {
      successRates[index] = 0; // If no messages for that day, set rate to 0%
    }
  });

  return successRates;
}

const processFailedMessages = (failedMessages) => {
  // Create map to store counts for message_from numbers only
  const phoneNumberCounts = new Map();

  failedMessages.forEach((msg) => {
    // Count only 'message_from' numbers
    if (msg.message_from) {
      phoneNumberCounts.set(
        msg.message_from,
        (phoneNumberCounts.get(msg.message_from) || 0) + 1
      );
    }
  });

  // Convert to array and sort by count in descending order
  const sortedPhoneNumbers = Array.from(phoneNumberCounts.entries())
    .sort((a, b) => b[1] - a[1]) // Sort by count (descending)
    .slice(0, 3); // Take only top 3

  // Split into separate arrays for labels and counts
  const phoneNumbers = sortedPhoneNumbers.map((item) => item[0]);
  const counts = sortedPhoneNumbers.map((item) => item[1]);

  return {
    labels: phoneNumbers,
    counts: counts,
  };
};
</script>

<template>
  <div>
    <div v-if="isLoading" class="card text-center my-4">
      <div class="spinner-border text-primary loading-spinner" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <p class="mt-2">Loading data...</p>
    </div>
    <!-- Line Chart -->
    <div v-else>
      <div v-if="isChartVisible" class="lineChart-height card">
        <p v-if="!chartData">Loading chart data...</p>
        <Line v-else :data="chartData" :options="chartOptions" />
      </div>
      <span v-else class="card pie-chart-individual card-isChartDisable">
        <p class="text-isChartDisable">
          No results found for the applied filters
        </p>
      </span>

      <!-- Pie Charts -->
      <div class="pie-chart-div">
        <span
          v-if="isChartVisible"
          class="card pie-chart-individual pie-delivery-height justify-center"
        >
          <p class="overall-deliver-text">Overall Delivery Rate</p>
          <Doughnut :data="PieDeliverData" :options="pieDeliverOption" />
        </span>

        <span v-else class="card pie-chart-individual card-isChartDisable">
          <p class="text-isChartDisable">
            No results found for the applied filters
          </p>
        </span>

        <span
          v-if="isChartVisible"
          class="card pie-chart-individual pie-delivery-height justify-center"
        >
          <p class="overall-deliver-text">Overall Error Rate</p>
          <Doughnut :data="PieErrorData" :options="pieErrorOption" />
        </span>
        <span v-else class="card pie-chart-individual card-isChartDisable">
          <p class="text-isChartDisable">
            No results found for the applied filters
          </p>
        </span>
        <span
          v-if="isChartVisible && failedMessageCount.value > 0"
          class="card pie-chart-third pie-delivery-height justify-center"
        >
          <p class="overall-deliver-text">Top Numbers with Errors</p>
          <Bar
            :data="trackChartData"
            :options="trackChartOption"
            style="max-height: 380px"
          />
        </span>
        <span v-else class="card pie-chart-third card-isChartDisable">
          <p class="text-isChartDisable">
            No results found for the applied filters
          </p>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card {
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pie-delivery-height {
  height: 500px;
}

.pie-error-width {
  width: 200px;
}

.lineChart-height {
  height: 350px;
  margin-top: 20px;
}

.overall-deliver-text {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.loading-spinner {
  margin: auto;
}

.pie-chart-div {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 16px;
}

.pie-chart-individual {
  flex-basis: 25%;
}

.pie-chart-third {
  flex-basis: 50%; /* 1/4 of the container's width */
}

.pieChart-span-height {
  height: 400px;
}

.card-isChartDisable {
  height: 50px;
}

.text-isChartDisable {
  font-size: 13px;
  font-weight: 600;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
</style>
