<!-- SimpleTooltip.vue -->
<template>
  <div class="tooltip-wrapper">
    <slot></slot>
    <span class="tooltip-text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "SimpleTooltip",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 25px;
}

.tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #c9c8c8;
  text-align: start;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Changed from bottom: 125% to top: 125% */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: pre-line;
  font-size: 13px;
}

.tooltip-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Changed from top: 100% to bottom: 100% */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent; /* Changed border color order */
}
</style>
