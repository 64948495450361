<script setup>
import { ref, defineProps, computed } from "vue";
import axios from "axios";
import { useToast } from 'vue-toast-notification';
import { convertTimestampToLocal } from "@/utils";
import DeleteConfirmationModal from "@/components/Modal/index.vue";
import Tooltip from "@/components/Tooltip/index.vue";

const props = defineProps({
  headings: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  itemClick: {
    type: Function,
    required: true
  },
  onClickDelete: {
    type: Function,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: true
  }
});

const toast = useToast();
const sortColumn = ref('');
const sortDirection = ref('asc');
const currentPage = ref(1);
const itemsPerPage = ref(10);
const deleteId = ref(-1);
const isDownloading = ref('')
const itemsPerPageOptions = [5, 10, 25, 50];
const showDeleteModal = ref(false)

const sort = (column) => {
  
  if (sortColumn.value === column) {
    sortDirection.value = sortDirection.value === 'asc' ? 'desc' : 'asc';
  } else {
    sortColumn.value = column;
    sortDirection.value = 'asc';
  }
};

const onDelete = async () => {
  props.onClickDelete(deleteId.value)
  showDeleteModal.value = false
  
};

const totalPages = computed(() => Math.ceil(sortedData.value.length / itemsPerPage.value));

const paginatedData = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return sortedData.value.slice(start, end);
});

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const resetPage = () => {
  currentPage.value = 1;
};

const sortedData = computed(() => {
  if (!sortColumn.value) return props.data;

  return [...props.data].sort((a, b) => {
    let aVal = a[sortColumn.value];
    
    let bVal = b[sortColumn.value];

    if (sortColumn.value === 'startDate' || sortColumn.value === 'endDate' || sortColumn.value === 'createdAt') {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    }

    if (aVal < bVal) return sortDirection.value === 'asc' ? -1 : 1;
    if (aVal > bVal) return sortDirection.value === 'asc' ? 1 : -1;
    return 0;
  });
});

const filteredHeadings = computed(() => {
  return [
  {field: "Id", value: "id"},
  {field: "Report Name", value: null},
  {field: "Start Date", value: "startDate"},
  {field: "End Date", value: "endDate"},
  {field: "Status", value: "status"},
  {field: "Created Date", value: "createdAt"},
  {field: "Action", value: null},
  ]
});

// const onMessageStatusClick = (jobId) => {
//   router.push(`/chats/${jobId}`);
// };

/* To consider later
const onStatusClick = (job) => {
  if (userRole.value === "ADMIN") {
    router.push(`/${job.jobType.toLowerCase()}/${job.jobId}/edit`);
  }
};
*/

const visiblePageNumbers = computed(() => {
  const totalPageCount = totalPages.value;
  const current = currentPage.value;
  const maxVisiblePages = 3; // Adjust this number to show more or fewer page numbers
  
  let startPage, endPage;
  if (totalPageCount <= maxVisiblePages) {
    // If total pages are less than max visible, show all pages
    startPage = 1;
    endPage = totalPageCount;
  } else {
    // Calculate start and end pages
    const middlePage = Math.floor(maxVisiblePages / 2);
    if (current <= middlePage) {
      startPage = 1;
      endPage = maxVisiblePages;
    } else if (current > totalPageCount - middlePage) {
      startPage = totalPageCount - maxVisiblePages + 1;
      endPage = totalPageCount;
    } else {
      startPage = current - middlePage;
      endPage = current + middlePage;
    }
  }
  
  // Generate page numbers
  let range = [];
  for (let i = startPage; i <= endPage; i++) {
    range.push(i);
  }
  
  // Add ellipsis if necessary
  if (startPage > 1) range.unshift('...');
  if (endPage < totalPageCount) range.push('...');
  
  // Always show first and last page
  if (startPage > 1) range.unshift(1);
  if (endPage < totalPageCount) range.push(totalPageCount);
  
  return range;
});

const goToPage = (page) => {
  currentPage.value = page;
};

const goToFirstPage = () => {
  currentPage.value = 1;
};

const goToLastPage = () => {
  currentPage.value = totalPages.value;
};

// const getFilenameFromHeaders = (headers) => {
//   const contentDisposition = headers['content-disposition']
//   if (!contentDisposition) return null

//   // Try different patterns for filename
//   const patterns = [
//     /filename\*=UTF-8''([^;]+)/i,  // RFC 5987
//     /filename="([^"]+)"/i,         // Quoted filename
//     /filename=([^;]+)/i            // Unquoted filename
//   ]

//   for (const pattern of patterns) {
//     const match = contentDisposition.match(pattern)
//     if (match && match[1]) {
//       // URL decode the filename
//       try {
//         return decodeURIComponent(match[1].trim())
//       } catch (e) {
//         console.warn('Failed to decode filename:', e)
//         return match[1].trim()
//       }
//     }
//   }

//   return null
// }

const handleDownload = async (reportId) => {
  if (isDownloading.value) return;

  isDownloading.value = reportId;
  try {
    // First get the presigned URL
    const urlResponse = await axios.post(
      `${process.env.VUE_APP_API_URL}/job/reports/download`,
      { reportId }
    );

    if (!urlResponse.data.downloadUrl) {
      throw new Error('No download URL received');
    }

    // Instead of using axios to download, use window.location or fetch
    // Option 1: Direct browser navigation
    window.location.href = urlResponse.data.downloadUrl;

    // Option 2: Using fetch (if you need more control)
    /*
    const response = await fetch(urlResponse.data.downloadUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', urlResponse.data.filename || 'report.zip');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
    */
    
    toast.success('Download started');
  } catch (error) {
    console.error('Download error:', error);
    
    if (error.code === 'ERR_NETWORK') {
      toast.error('Network error occurred. Please try again.');
    } else if (error.response) {
      const errorMessage = error.response.data?.message || 'Failed to download report';
      toast.error(errorMessage);
    } else if (error.request) {
      toast.error('No response received from server');
    } else {
      toast.error(error.message || 'Failed to download report');
    }
  } finally {
    isDownloading.value = '';
  }
};
</script>

<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>MDR Rep</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <div v-if="isLoading" class="text-center my-4">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-2">Loading data...</p>
        </div>
        <table v-else class="table align-items-center justify-content-center mb-0">
          <thead>
            <tr>
              <th
                v-for="(heading, index) in filteredHeadings "
                :key="index"
                @click="sort(heading.value)"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center cursor-pointer"
              >
                {{ heading.field }}
                <span v-if="sortColumn === heading.value && heading.value !== null">
                  {{ sortDirection === 'asc' ? '▲' : '▼' }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <!-- <template v-for="(item, index) in paginatedData" :key="index"> -->
              <tr 
                v-for="(item, index) in paginatedData" 
                :key="index"
                :class="index % 2 === 0 ? 'bg-white' : 'bg-light'"
              >
                <td class="px-4 text-center">
                  <p class="text-xs font-weight-bold mb-0 text-center">
                    {{ item.id }}
                  </p>
                </td>
                <td class="px-4 text-center">
                  <p class="text-xs font-weight-bold mb-0 text-center">
                    {{ item.reportName }}
                  </p>
                </td>
                <td class="px-4 text-center">
                  <p class="text-xs font-weight-bold mb-0 text-center">
                    {{ item.startDate }}
                  </p>
                </td>
                <td class="px-4 text-center">
                  <p class="text-xs font-weight-bold mb-0 text-center">
                    {{ item.endDate }}
                  </p>
                </td>
                <td
                  class="px-4 text-center"
                >
                  <p class="text-xs font-weight-bold mb-0">
                    {{ item.status }}
                  </p>
                </td>
                <td
                  class="px-4 text-center"
                >
                  <p class="text-xs font-weight-bold mb-0">
                    {{ convertTimestampToLocal(item.createdAt) }}
                  </p>
                </td>
                <td class="px-4 cursor-pointer text-center">
                  <Tooltip text="Download Report">
                    <i
                      v-if="item.status === 'COMPLETED'"
                      :class="[
                        'fas',
                        'p-2',
                        'text-sm',
                        isDownloading === item.id ? 'fa-spinner fa-spin text-gray-400' : 'fa-download text-primary opacity-10 cursor-pointer hover:opacity-100',
                      ]"
                      @click.prevent="handleDownload(item.id)"
                      :style="{ pointerEvents: isDownloading === item.id ? 'none' : 'auto' }"
                    ></i>
                  </Tooltip>
                </td>
              </tr>
            <!-- </template> -->
          </tbody>
        </table>
        <div v-if="totalPages > 1" class="pagination-controls d-flex justify-content-between align-items-center mt-3 px-5">
          <div>
            <span class="me-2">Rows per page:</span>
            <select v-model="itemsPerPage" @change="resetPage">
              <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="pagination-nav">
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="goToFirstPage"
              :disabled="currentPage === 1"
              title="First Page"
            >
              &lt;&lt;
            </button>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="prevPage"
              :disabled="currentPage === 1"
              title="Previous Page"
            >
              &lt;
            </button>
            <template v-for="pageNum in visiblePageNumbers" :key="pageNum">
              <button
                v-if="pageNum !== '...'"
                class="btn btn-sm me-1"
                :class="pageNum === currentPage ? 'btn-primary' : 'btn-secondary'"
                @click="goToPage(pageNum)"
              >
                {{ pageNum }}
              </button>
              <span v-else class="mx-1">...</span>
            </template>
            <button
              class="btn btn-sm btn-secondary me-1"
              @click="nextPage"
              :disabled="currentPage === totalPages"
              title="Next Page"
            >
              &gt;
            </button>
            <button
              class="btn btn-sm btn-secondary"
              @click="goToLastPage"
              :disabled="currentPage === totalPages"
              title="Last Page"
            >
              &gt;&gt;
            </button>
          </div>
        </div>
        <DeleteConfirmationModal 
          :isOpen="showDeleteModal"
          content='Do you want to delete this job?'
          itemName="User Account"
          @confirm="onDelete"
          @cancel="showDeleteModal = false"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.pagination-controls select {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}

.pagination-controls button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-nav {
  display: flex;
  align-items: center;
}

.table tbody tr:nth-child(even) {
  background-color: #f9f9f9;  /* Light gray for even rows */
}

.table tbody tr:nth-child(odd) {
  background-color: #ffffff;  /* White for odd rows */
}

.fa-spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
</style>