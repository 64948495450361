<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import Sidenav from "./components/Sidenav";
import AddMedia from "./views/MediaPage/AddMedia.vue";
import AddAgent from "./views/CreatePage/AddAgent.vue";
import AddPhoneList from "./views/CreatePage/AddPhoneList.vue";
import EditJobPanel from "./views/CreatePage/EditJob.vue";
import AddDNC from "./views/DNCManager/AddDNC.vue";
import AddIdentity from "./views/Identities/AddIdentity.vue";
import AssignMedia from "./views/MediaPage/AssignMedia.vue";
// import Configurator from "@/examples/Configurator.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
// import AppFooter from "@/examples/Footer.vue";

const store = useStore();
const isNavFixed = computed(() => store.state.app.isNavFixed);
const darkMode = computed(() => store.state.app.darkMode);
const isAbsolute = computed(() => store.state.app.isAbsolute);
const showSidenav = computed(() => store.state.app.showSidenav);
const layout = computed(() => store.state.app.layout);
const showNavbar = computed(() => store.state.app.showNavbar);
const showAddMediaPanel = computed(() => store.state.mediaData.showAddMediaPanel);
const showAssignMediaPanel = computed(() => store.state.mediaData.showAssignMediaPanel);
const showAddAgentPanel = computed(() => store.state.jobData.showAddAgentPanel);
const showEditJobPanel = computed(() => store.state.jobData.showEditJobPanel);
const showAddPhoneListPanel = computed(() => store.state.jobData.showAddPhoneListPanel);
const showAddDNCPanel = computed(() => store.state.dncData.showAddDNCPanel);
const showAddIdentityPanel = computed(
  () => store.state.identityData.showAddIdentityPanel
);
const userId = computed(() => store.getters["user/currentUser"]?.id);
const toggleAddMediaPanel = () => store.commit("mediaData/toggleAddMediaPanel");
const toggleAddAgentPanel = () => store.commit("jobData/toggleAddAgentPanel");
const toggleAddPhoneListPanel = () => store.commit("jobData/toggleAddPhoneListPanel");
const toggleEditJobPanel = () => store.commit("jobData/toggleEditJobPanel");
const toggleAddDNCPanel = () => store.commit("dncData/toggleAddDNCPanel");
const toggleAddIdentityPanel = () => store.commit("identityData/toggleAddIdentityPanel");
const toggleAssignMediaPanel = () => store.commit("mediaData/toggleAssignMediaPanel");
// const showFooter = computed(() => store.state.app.showFooter);
// const showConfig = computed(() => store.state.app.showConfig);
// const hideConfigButton = computed(() => store.state.app.hideConfigButton);
// const toggleConfigurator = () => store.commit("app/toggleConfigurator");

onMounted(async () => {
  if (userId.value) {
    await store.dispatch("agentData/fetchAgents", { userId: userId.value });
    await store.dispatch("identityData/fetchIdentities", { userId: userId.value });
    await store.dispatch("phoneListData/fetchPhoneLists", { userId: userId.value });
    await store.dispatch("phoneListData/fetchSuppressionLists", { userId: userId.value });
    await store.dispatch("user/fetchUsers")
  }
});

const navClasses = computed(() => {
  return {
    "position-sticky bg-white left-auto top-2 z-index-sticky":
      isNavFixed.value && !darkMode.value,
    "position-sticky bg-default left-auto top-2 z-index-sticky":
      isNavFixed.value && darkMode.value,
    "position-absolute px-4 mx-0 w-100 z-index-2": isAbsolute.value,
    "px-0 mx-4": !isAbsolute.value,
  };
});
</script>
<template>
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>

  <sidenav v-if="showSidenav" />

  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <navbar :class="[navClasses]" v-if="showNavbar" />

    <router-view />

    <add-media :toggle="toggleAddMediaPanel" :class="[showAddMediaPanel ? 'show' : '']" />

    <assign-media :toggle="toggleAssignMediaPanel" :class="[showAssignMediaPanel ? 'show' : '']" />

    <add-agent :toggle="toggleAddAgentPanel" :class="[showAddAgentPanel ? 'show' : '']" />

    <add-phone-list
      :toggle="toggleAddPhoneListPanel"
      :class="[showAddPhoneListPanel ? 'show' : '']"
    />
    <edit-job-panel :toggle="toggleEditJobPanel" :class="[showEditJobPanel ? 'show' : '']"/>

    <add-d-n-c :toggle="toggleAddDNCPanel" :class="[showAddDNCPanel ? 'show' : '']" />

    <add-identity
      :toggle="toggleAddIdentityPanel"
      :class="[showAddIdentityPanel ? 'show' : '']"
    />
    <!-- <app-footer v-show="showFooter" /> -->

    <!-- <configurator
      :toggle="toggleConfigurator"
      :class="[showConfig ? 'show' : '', hideConfigButton ? 'd-none' : '']"
    /> -->
  </main>
</template>
